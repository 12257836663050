import store from "src/store"
import { LogLevels } from "./types"

interface TrackingDataType {
  level: LogLevels
  data?: { [s: string]: any } | Error
  tags?: { [s: string]: string }
  app?: string | { [s: string]: string }
  email?: string
  workspace?: string
  includeUserData?: boolean
}

/**
 * Generates tracking data for logging purposes.
 *
 * @param {Object} params - The parameters for generating tracking data.
 * @param {LogLevels} params.level - The log level.
 * @param {Object} [params.data] - Optional additional data to include in the tracking data.
 * @param {Object} [params.tags] - Optional tags to include in the tracking data.
 * @param {string | Object} [params.app="ztmesh-web-portal"] - The application name or additional app data.
 * @param {boolean} [params.includeUserData=true] - Whether to include user data (email and workspace) in the tracking data.
 * @returns {TrackingDataType} The generated tracking data.
 */
export const getTrackingData = ({
  data,
  tags,
  level,
  app = "ztmesh-web-portal",
  includeUserData = true,
}: TrackingDataType): TrackingDataType => {
  const { email, workspace } = store.getState()?.auth?.user
  const userData = includeUserData
    ? {
        ...(email ? { email } : {}),
        ...(workspace ? { workspace } : {}),
      }
    : {}

  return { ...data, ...tags, ...userData, level, app }
}
