import { CATALOG_APPS } from "src/constants"
import { REQUEST_HEADERS } from "src/services"
import useSWR from "swr"

export type CatalogAppsPayloadType = {
  id: number
  indicator: string
  appCode: string
  appName: string
  appTooltip: string
  appMgmt: boolean
  appIcon: string
  appUrl: string
  appLicense: boolean
  appDefault: boolean
}

type UseCatalogAppsResponseType = {
  data: CatalogAppsPayloadType[]
  isLoading: boolean
  error: any
}

type UseCatalogAppsType = {
  shouldFetch: boolean
}

/**
 * Custom hook to fetch catalog apps data.
 *
 * @param {UseCatalogAppsType} props - Props object containing the `shouldFetch` flag.
 *
 * @returns {UseCatalogAppsResponseType} The response object containing the catalog apps data, loading state, and error.
 */
const useCatalogApps = ({ shouldFetch }: UseCatalogAppsType): UseCatalogAppsResponseType => {
  const { data, error } = useSWR(shouldFetch ? [CATALOG_APPS, REQUEST_HEADERS] : null)

  const payload = data?.payload

  return {
    data: payload ? payload?.data : [],
    isLoading: !error && !data,
    error,
  }
}

export default useCatalogApps
