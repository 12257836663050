import { PROFILE_IMAGE, TENANT_ADMIN_BASE_URL } from "src/constants"
import { RoleValueType } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import { API_URL, REQUEST_HEADERS } from "src/services"
import useSWR from "swr"

type UseProfileImageResponseType = {
  data?: {
    profileImage: string
  }
  isLoading: boolean
  error: any
  isValidating: boolean
  getProfileImage: () => void
}

/**
 * Custom hook to fetch the profile image URL based on the user's role.
 *
 * This hook uses SWR to fetch the profile image URL based on the user's role, returning the image data,
 * loading status, error, validation status, and a function to refresh the profile image.
 *
 * @param {RoleValueType} role - The user's role, which determines the API endpoint to call.
 *
 * @returns {UseProfileImageResponseType} The profile image data, loading, error, and validation states.
 */
const useProfileImage = (role: RoleValueType): UseProfileImageResponseType => {
  const url = isTenantRole(role) ? `${API_URL}${TENANT_ADMIN_BASE_URL}${PROFILE_IMAGE}` : `${API_URL}${PROFILE_IMAGE}`

  const { data, error, mutate, isValidating } = useSWR([url, REQUEST_HEADERS])

  const payload = data?.payload

  return {
    data: payload
      ? { profileImage: payload?.profileImage ? `${payload.profileImage}?timestamp=${Date.now()}` : "" }
      : undefined,
    isLoading: !error && !data,
    error,
    getProfileImage: mutate,
    isValidating,
  }
}

export default useProfileImage
