export const ALPHANUMERIC_SPACE_HYPHEN_REGEX = /^([a-zA-Z0-9]+(\s?[_'-]\s?|\s)?)*[a-zA-Z0-9]+$/
export const DEVICE_ALIAS_REGEX = /^[a-zA-Z0-9._-]+(?: [a-zA-Z0-9._-]+)*$/

export const MAC_ADDRESS_REGEX =
  /^(?:[0-9A-Fa-f]{2}([-:])(?:[0-9A-Fa-f]{2}\1){4}[0-9A-Fa-f]{2}|[0-9A-Fa-f]{4}(\.)([0-9A-Fa-f]{4}\2)[0-9A-Fa-f]{4}|[0-9A-Fa-f]{12}|[0-9A-Fa-f]{6}\.[0-9A-Fa-f]{6})$/

export const PARTIAL_MAC_ADDRESS =
  /^(?:[0-9A-Fa-f]{2}([-:])(?:[0-9A-Fa-f]{2}\1){0,3}[0-9A-Fa-f]{0,2}|[0-9A-Fa-f]{4}(\.)?[0-9A-Fa-f]{0,4}|[0-9A-Fa-f]{2,10})$/

export const APP_VERSION_REGEX = /\d+\.\d+\.\d+/
