import { useState } from "react"
import { ALERTS } from "src/craas/apiURLs"
import { REQUEST_HEADERS } from "src/services"
import { getExternalApiUrl, keysToCamelCase } from "src/utils"
import useSWR from "swr"

export type AlertType = {
  id: string
  timestamp: string
  app: string
  appName: string
  messageMetadataId: number
  messageMetadataType: string
  messageMetadataName: string
  summary: string
  severityName: string
  categoryName: string
  severityId: number
  categoryId: number
  source: SourceType
  tags: Array<TagsType>
  acknowledged: boolean
  siteId: string
  siteName: string
  alertPolicyId: number
  alertPolicyName: string
  alertRuleId: number
  acknowledgedUsername: string
}

type SourceType = {
  sourceTypeId: string
  sourceName: string
  sourceType: string
  sourceId: string
}

type TagsType = {
  id: number
  name: string
  value: string
  isHidden: boolean
}

export type AlertsPayloadType = {
  alerts: AlertType[] | null
  total: number
  from?: number
  to?: number
}

type UseAlertsResponseType = {
  data?: AlertsPayloadType
  isLoading: boolean
  error: any
  isValidating: boolean
  getAlerts: () => void
}

export type UseAlertsType = {
  shouldFetch?: boolean
  startTime: number
  endTime: number
  page?: number
  limit?: number
  refreshInterval?: number
  filterQuery?: string
  query?: string
}

/**
 * Retrieves the GDC identifier for the given domain.
 * The GDC identifier is used for mapping the domain to a particular service or environment.
 *
 * @param {string} domain - The domain name for which the GDC identifier is required.
 *
 * @returns {string} - The corresponding GDC identifier.
 */
export const getExternalApiGdc = (domain: string): string => {
  const gdcNames: { [domain: string]: string } = {
    "zta-tme": "tme",
    "g3r1-uz": "g3",
    "g2r1-uz": "g2",
    "feature-endsystem": "g2",
    default: "ztna1",
  }

  return gdcNames[domain] ?? gdcNames.default
}

/**
 * Constructs the external API RDC URL based on the provided server name.
 * The function appends the server to the environment URL to generate the full API URL.
 *
 * @param {string} server - The server name to be appended to the external API URL.
 *
 * @returns {string} - The constructed API URL for the provided server.
 */
export const getExternalApiRdc = (server: string): string => {
  const envUrl: string = getExternalApiUrl() ?? ""
  let API_URL = envUrl

  if (envUrl && envUrl !== "") {
    let envDomain = envUrl.split(".")
    envDomain[0] += `-${server}`
    API_URL = envDomain.join(".")
  }

  return API_URL
}

/**
 * Custom hook to fetch alert data based on various query parameters and filters.
 * It returns the alert data, loading state, error, and a function to trigger data re-fetch.
 *
 * @param {UseAlertsType} params - The configuration parameters for fetching alerts.
 * @param {boolean} params.shouldFetch - Determines if data should be fetched (default is true).
 * @param {number} params.startTime - The start time for fetching alerts in UNIX timestamp.
 * @param {number} params.endTime - The end time for fetching alerts in UNIX timestamp.
 * @param {number} [params.page=1] - The page number for pagination (default is 1).
 * @param {number} [params.limit=100] - The number of alerts per page (default is 100).
 * @param {number} [params.refreshInterval] - The interval in milliseconds to refresh data.
 * @param {string} [params.filterQuery] - Optional filter query for alert data.
 * @param {string} [params.query] - Optional query string for the API call.
 *
 * @returns {UseAlertsResponseType} - Returns the alert data, loading state, error, and mutation function.
 */
const useAlerts = ({
  filterQuery,
  shouldFetch = true,
  startTime,
  endTime,
  page = 1,
  limit = 100,
  refreshInterval,
}: UseAlertsType): UseAlertsResponseType => {
  const [alertsData, setAlertsData] = useState<AlertsPayloadType | undefined>()
  const [error, setError] = useState<any>(null)

  /**
   * Processes and sets the alert data after a successful fetch.
   *
   * @param {any} swrData - The raw data fetched from the API.
   */
  const fetchData = async (swrData: any) => {
    try {
      setAlertsData({
        total: swrData?.pagination?.totalCount,
        alerts: swrData?.data ? keysToCamelCase(swrData.data) : undefined,
      })
    } catch (error) {
      setError(error)
    }
  }

  /**
   * Fetches data from the provided URL using the given headers.
   *
   * @param {string} url - The API URL from which data needs to be fetched.
   * @param {HeadersInit} headers - The headers to include in the fetch request.
   * @returns {Promise<any>} - The parsed JSON response from the API.
   */
  const fetcher = async (url: string, headers: HeadersInit): Promise<any> => {
    const response = await fetch(url, { headers, credentials: "include" })
    if (!response.ok) {
      error.response = response
    }
    return response.json()
  }

  const isoStartTime = new Date(startTime).toISOString()
  const isoEndTime = new Date(endTime).toISOString()

  const API_URL = getExternalApiRdc("cas") ?? ""

  const url = `${API_URL}${ALERTS}?startTime=${isoStartTime}&endTime=${isoEndTime}&page=${page}&limit=${limit}&order=DESC`

  const {
    error: swrError,
    data: swrData,
    mutate,
    isValidating,
  } = useSWR(shouldFetch ? [url, REQUEST_HEADERS] : null, {
    refreshInterval,
    fetcher,
    onSuccess: (data) => {
      if (!swrError) {
        fetchData(data)
      }
    },
  })

  return {
    data: alertsData,
    isLoading: !swrError && (!alertsData || !swrData),
    error: error || swrError,
    isValidating,
    getAlerts: mutate,
  }
}

export default useAlerts
