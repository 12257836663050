import { ALPHA_NUM_WO_HYP_REGEX, URL_WITH_PROTOCOL_REGEX } from "src/utils/constants"
import * as yup from "yup"
import {
  ALPHANUMERIC_SPACE_HYPHEN_REGEX,
  DEVICE_ALIAS_REGEX,
  MAC_ADDRESS_REGEX,
  PARTIAL_MAC_ADDRESS,
} from "./constants"
import { ERROR_MESSAGES } from "./craasUserMessages"

/**
 * Validation schema for deploying a RadSec Proxy using Yup.
 * 
 * This schema validates the following fields:
 * 
 * - `name`: A required string that must be alphanumeric, can include spaces and hyphens, 
 *   and must be between 1 and 31 characters in length.
 * - `site`: A required object containing `id` and `name` as strings.
 * - `certificatesRotationTimeInDays`: A required number that must be between 30 and 365 days.
 * - `secret`: A required string that must be alphanumeric without hyphens, and must be 
 *   between 3 and 32 characters in length.
 * 
 * @constant
 * @type {yup.ObjectSchema}
 */
export const deployRadSecProxyValidation = yup.object().shape({
  name: yup
    .string()
    .required("RadSec Proxy Name is Required")
    .matches(ALPHANUMERIC_SPACE_HYPHEN_REGEX, ERROR_MESSAGES.INVALID_NAME_INPUT)
    .min(1, "RadSec Proxy Name must not be smaller than 1 character")
    .max(31, "RadSec Proxy Name must not be longer than 31 characters"),
  site: yup.object().shape({ id: yup.string(), name: yup.string() }).required("Network Site is required"),
  certificatesRotationTimeInDays: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Certificate Rotation Time is required")
    .min(30, "Certificate Rotation Time in days must be greater than or equal to 30")
    .max(365, "Certificate Rotation Time in days must be less than or equal to 365"),
  secret: yup
    .string()
    .required("Shared secret is required")
    .matches(ALPHA_NUM_WO_HYP_REGEX, "Shared Secret can only include alphanumeric characters")
    .min(3, "Please ensure shared secret is between 3 and 32 characters in length")
    .max(32, "Please ensure shared secret is between 3 and 32 characters in length"),
})

/**
 * Validation schema for adding devices using Yup.
 * 
 * This schema validates the following fields:
 * 
 * - `macAddress`: A required string that must match the MAC address format defined by `MAC_ADDRESS_REGEX`.
 *   - Error message if not provided: "MAC Address is required".
 *   - Error message if invalid: "Invalid MAC Address".
 * 
 * - `description`: An optional string with a maximum length of 255 characters.
 *   - Error message if too long: "Description must not be longer than 255 characters".
 * 
 * - `alias`: An optional string that must meet the following criteria:
 *   - Must contain only alphanumeric characters, hyphens, underscores, and periods.
 *     - Error message if invalid: "Alias must contain only alphanumeric characters, hyphens, underscores, and periods."
 *   - Must be at least 3 characters long.
 *     - Error message if too short: "Alias must not be smaller than 3 characters".
 *   - Must be no longer than 31 characters.
 *     - Error message if too long: "Alias must not be longer than 31 characters".
 */
export const addDevicesValidation = yup.object().shape({
  macAddress: yup.string().required("MAC Address is required").matches(MAC_ADDRESS_REGEX, "Invalid MAC Address"),
  description: yup.string().max(255, "Description must not be longer than 255 characters"),
  alias: yup
    .string()
    .notRequired()
    .test(
      "is-valid-alias",
      "Alias must contain only alphanumeric characters, hyphens, underscores, and periods.",
      (value) => value === undefined || value === "" || DEVICE_ALIAS_REGEX.test(value),
    )
    .test(
      "min-length",
      "Alias must not be smaller than 3 characters",
      (value) => value === undefined || value === "" || value.length >= 3,
    )
    .test(
      "max-length",
      "Alias must not be longer than 31 characters",
      (value) => value === undefined || value === "" || value.length <= 31,
    ),
})

/**
 * Validation schema for adding a custom MAC address.
 * 
 * This schema validates the following fields:
 * - `partialMacAddress`: A required string that must match the `PARTIAL_MAC_ADDRESS` regex pattern.
 * - `alias`: An optional string that must:
 *   - Contain only alphanumeric characters, hyphens, underscores, and periods.
 *   - Be at least 3 characters long if provided.
 *   - Be no more than 31 characters long if provided.
 * 
 * @constant
 * @type {yup.ObjectSchema}
 */
export const addCustomMacValidation = yup.object().shape({
  partialMacAddress: yup
    .string()
    .required("Partial MAC Address is required")
    .matches(PARTIAL_MAC_ADDRESS, "Invalid MAC Address"),
  alias: yup
    .string()
    .notRequired()
    .test(
      "is-valid-alias",
      "Alias must contain only alphanumeric characters, hyphens, underscores, and periods.",
      (value) => value === undefined || value === "" || DEVICE_ALIAS_REGEX.test(value),
    )
    .test(
      "min-length",
      "Alias must not be smaller than 3 characters",
      (value) => value === undefined || value === "" || value.length >= 3,
    )
    .test(
      "max-length",
      "Alias must not be longer than 31 characters",
      (value) => value === undefined || value === "" || value.length <= 31,
    ),
})

/**
 * Validation schema for adding a MAC OUI (Organizationally Unique Identifier) with optional alias.
 * 
 * The alias field is validated with the following rules:
 * - It is not required.
 * - If provided, it must contain only alphanumeric characters, hyphens, underscores, and periods.
 * - If provided, it must be at least 3 characters long.
 * - If provided, it must not exceed 31 characters in length.
 * 
 * @constant
 * @type {yup.ObjectSchema}
 */
export const addMacOuiValidation = yup.object().shape({
  alias: yup
    .string()
    .notRequired()
    .test(
      "is-valid-alias",
      "Alias must contain only alphanumeric characters, hyphens, underscores, and periods.",
      (value) => value === undefined || value === "" || DEVICE_ALIAS_REGEX.test(value),
    )
    .test(
      "min-length",
      "Alias must not be smaller than 3 characters",
      (value) => value === undefined || value === "" || value.length >= 3,
    )
    .test(
      "max-length",
      "Alias must not be longer than 31 characters",
      (value) => value === undefined || value === "" || value.length <= 31,
    ),
})

/**
 * Validation schema for connecting devices.
 * 
 * This schema validates the following fields:
 * - `deviceName`: A required string with a minimum length of 1 character and a maximum length of 31 characters.
 * - `serialNumber`: A required string.
 * 
 * @example
 * const isValid = connectDevicesValidation.isValidSync({
 *   deviceName: "Device1",
 *   serialNumber: "1234567890"
 * });
 * 
 * @returns {yup.ObjectSchema} The validation schema for connecting devices.
 */
export const connectDevicesValidation = yup.object().shape({
  deviceName: yup
    .string()
    .required("Device Name is required")
    .min(1, "Device Name must not be smaller than 1 character")
    .max(31, "Device Name must not be longer than 31 characters"),
  serialNumber: yup.string().required("Serial Number is required"),
})

/**
 * Validation schema for connecting with an OCSP responder.
 * 
 * This schema validates the following fields:
 * - `url`: A required string that must match the `URL_WITH_PROTOCOL_REGEX` pattern.
 * 
 * @constant
 * @type {yup.ObjectSchema}
 * 
 * @example
 * const isValid = connectWithOcspResponderValidation.isValidSync({
 *   url: "https://example.com"
 * });
 * 
 * @throws {ValidationError} If the validation fails, an error is thrown with the appropriate message.
 */
export const connectWithOcspResponderValidation = yup.object().shape({
  url: yup.string().required("URL is Required").matches(URL_WITH_PROTOCOL_REGEX, "Invalid URL"),
})

/**
 * Validation schema for matching criteria for clients using Yup.
 * 
 * This schema is used to validate the matching criteria for clients.
 * 
 * @example
 * const isValid = matchingCriteriaForClientsValidation.isValidSync({
 *   matcher: "example*pattern"
 * });
 * 
 * @remarks
 * The `matcher` field is currently commented out. If you need to validate the matcher from the UI,
 * uncomment the `matcher` field and ensure it meets the following criteria:
 * - It is a required string.
 * - It matches the regex pattern `^([\S]*)([*])+([\S]*)$`, which means it must be without spaces and include at least one asterisk (*).
 * - It has a maximum length of 63 characters.
 */
export const matchingCriteriaForClientsValidation = yup.object().shape({
  //Just uncoment if we are showing matcher from UI
  // matcher: yup
  //   .string()
  //   .required("Pattern is required")
  //   .matches(/^([\S]*)([*])+([\S]*)$/, "Pattern must be without spaces and include at least one asterisk (*)")
  //   .max(63, "Please ensure that the patterns falls within the range of 1 to 63"),
})

/**
 * Validation schema for rollout upgrade.
 * 
 * This schema validates the following fields:
 * - `connectorVersionCheck`: A boolean indicating whether the connector version check is enabled.
 * - `proxyVersionCheck`: A boolean indicating whether the proxy version check is enabled.
 * 
 * @constant
 * @type {yup.ObjectSchema}
 */
export const rolloutUpgradeValidation = yup.object().shape({
  connectorVersionCheck: yup.boolean(),
  proxyVersionCheck: yup.boolean(),
})
