import { RETRY_RELAY_UPGRADE } from "src/constants"
import { postRequest } from "src/services"

/**
 * Attempts to retry the upgrade process for a single relay identified by the given relay ID.
 *
 * @param {string} relayId - The unique identifier of the relay to be upgraded.
 * @returns {Promise<void>} - A promise that resolves when the upgrade request has been sent.
 */
export const retryUpgradeSingleRelay = async (relayId: string): Promise<void> => {
  const body = { id: relayId }
  await postRequest(RETRY_RELAY_UPGRADE, body)
}
