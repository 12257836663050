import { API_URL, REQUEST_HEADERS } from "src/services"
import { ChartsTimeIntervalFilterType } from "src/utils"
import useSWR, { SWRConfiguration, SWRResponse } from "swr"

type UseResponseType<DataType> = {
  data?: DataType
  isLoading: boolean
  error: any
  isValidating: boolean
  mutate: SWRResponse["mutate"]
}

/**
 * Custom hook to fetch data using SWR (stale-while-revalidate) strategy.
 *
 * @template PayloadType - The type of the payload data.
 * @template DataType - The type of the parsed data.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} [params.baseUrl=API_URL] - The base URL for the API.
 * @param {string | null} params.apiUrl - The API endpoint to fetch data from.
 * @param {SWRConfiguration} [params.options] - Optional SWR configuration options.
 * @param {any} [params.extraParserProps={}] - Additional properties to pass to the parser function.
 * @param {(data: PayloadType, extraParserProps?: any) => DataType} [params.parser] - Optional parser function to transform the fetched data.
 * @param {boolean} [params.useBaseURL=true] - Flag to determine whether to use the base URL.
 * @param {ChartsTimeIntervalFilterType} [params.timeFilterValue] - Optional time filter value for the request.
 * @param {Record<string, string>} [params.headers={}] - Optional headers to include in the request.
 *
 * @returns {UseResponseType<DataType>} The response object containing the fetched data, loading state, error, mutate function, and validation state.
 */
const useFetch = <PayloadType = any, DataType = PayloadType>({
  baseUrl = API_URL,
  apiUrl,
  options,
  parser,
  useBaseURL = true,
  timeFilterValue,
  headers = {},
  extraParserProps = {},
}: {
  baseUrl?: string
  apiUrl: string | null
  options?: SWRConfiguration
  extraParserProps?: any
  parser?: (data: PayloadType, extraParserProps?: any) => DataType
  useBaseURL?: boolean
  timeFilterValue?: ChartsTimeIntervalFilterType
  headers?: Record<string, string>
}): UseResponseType<DataType> => {
  const url = useBaseURL ? `${baseUrl}${apiUrl}` : apiUrl

  const { data, error, mutate, isValidating } = useSWR(
    apiUrl ? [url, { ...REQUEST_HEADERS, ...headers }, timeFilterValue] : null,
    options,
  )

  const payload = data?.payload || data

  const isLoading = !error && payload === undefined && !!apiUrl

  return {
    data:
      isLoading || !!error
        ? undefined
        : (parser && payload && parser(payload, extraParserProps)) || payload || undefined,
    isLoading,
    error,
    mutate,
    isValidating,
  }
}

export default useFetch
