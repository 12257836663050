import clsx from "clsx"
import { ENButton } from "en-react/dist/src/components/Button"
import { useZtnaButtonStyles } from "./ZtnaButton.styles"
import { ZtnaButtonProps } from "./ZtnaButton.types"
export type ButtonType = "secondary" | "danger" | "tertiary" | undefined

const getButtonVariant = (buttonType: string): ButtonType => {
  switch (buttonType) {
    case "tertiary":
      return "tertiary"
    case "secondary":
      return "secondary"
    case "error":
      return "danger"
  }
}

const ZtnaButton: React.FC<ZtnaButtonProps> = (props) => {
  const classes = useZtnaButtonStyles(props)
  const {
    id,
    title,
    hideText = false,
    name,
    dataTestid,
    onClick,
    className,
    contentColor,
    disabled,
    startIcon,
    endIcon,
    buttonType = "primary",
    href,
    type,
    fullWidth,
    iconClass,
  } = props

  return (
    <>
      <ENButton
        id={id}
        data-testid={dataTestid}
        variant={getButtonVariant(buttonType)}
        type={type}
        hideText={hideText}
        href={href}
        isDisabled={disabled}
        styleModifier={className}
        className={className}
        name={name}
        onClick={onClick}
        fullWidth={fullWidth}
        role="button"
      >
        <div
          className={clsx(iconClass, {
            [classes.customContentColor]: contentColor,
          })}
          slot="before"
        >
          {startIcon}
        </div>

        <div
          className={clsx(classes.button, iconClass, {
            [classes.customContentColor]: contentColor,
          })}
        >
          {/* {startIcon} */}
          {title && (
            <span
              className={clsx(classes.button, {
                [classes.customContentColor]: contentColor,
              })}
            >
              {title}
            </span>
          )}
          {/* {endIcon} */}
        </div>

        <div
          className={clsx(iconClass, {
            [classes.customContentColor]: contentColor,
          })}
          slot="after"
        >
          {endIcon}
        </div>
      </ENButton>
    </>
  )
}

export default ZtnaButton
