import { useState } from "react"
import { getRequest, postRequest, REQUEST_HEADERS } from "src/services"
import { ENPaginatedSelectLoadOptionsType } from "src/shared/components/FormComponents/FormComponents.types"
import { DROPDOWN_PAGE_SIZE } from "src/utils/constants"
import { DropDownType, OptionType } from "src/utils/utils.types"

export type PaginatedDropdownOptionType = OptionType & DropDownType

type FetchByIdType = () => Promise<{ selectedOptions: PaginatedDropdownOptionType[] }>

type PaginatedApiCallHookOptions<PayloadType = any, FetchByIdPayloadType = PayloadType> = {
  apiUrl: string
  fetchByIdUrl?: string
  pageSize?: number
  paginationType?: "page" | "offset"
  useBaseUrl?: boolean
  parser: (data: PayloadType) => { hasMore: boolean; options: PaginatedDropdownOptionType[]; additional?: any }
  fetchByIdParser?: (data: FetchByIdPayloadType) => { selectedOptions: PaginatedDropdownOptionType[] }
  pageParam?: string
  limitParam?: string
  requestType?: "get" | "post"
  body?: any
  fetchByIdBody?: any
  searchParam?: string
}

type PaginatedApiCallHookResponseType = <PayloadType = any, FetchByIdPayloadType = PayloadType>(
  options: PaginatedApiCallHookOptions<PayloadType, FetchByIdPayloadType>,
) => {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  loadOptions: ENPaginatedSelectLoadOptionsType
  loadOptionsById?: FetchByIdType
}

/**
 * Fetches data from an external API using the specified HTTP method.
 *
 * @param method - The HTTP method to use for the request ("post" or "get").
 * @param url - The URL of the external API endpoint.
 * @param body - The request payload to send with a "post" request. Defaults to an empty object.
 * @returns A promise that resolves to the JSON response from the API.
 *
 * @throws Will throw an error if the fetch request fails.
 */
const fetchExternalApi = async (method: "post" | "get", url: string, body: any = {}) => {
  const response = await fetch(url, {
    headers: REQUEST_HEADERS,
    method,
    ...(method === "post" ? { body: JSON.stringify(body) } : {}),
    credentials: "include",
  })
  return await response.json()
}

/**
 * Custom hook to handle paginated API calls for dropdowns.
 * 
 * This hook provides functionality to fetch paginated data from an API endpoint
 * and manage the loading state, success state, and error state of the request.
 * It also supports fetching data by ID.
 * 
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.apiUrl - The URL of the API endpoint to fetch data from.
 * @param {string} [params.fetchByIdUrl=""] - The URL of the API endpoint to fetch data by ID.
 * @param {number} [params.pageSize=DROPDOWN_PAGE_SIZE] - The number of items per page.
 * @param {string} [params.paginationType="offset"] - The type of pagination to use ("page" or "offset").
 * @param {boolean} [params.useBaseUrl=true] - Whether to use the base URL for requests.
 * @param {Function} params.parser - The function to parse the API response.
 * @param {Function} [params.fetchByIdParser] - The function to parse the API response when fetching by ID.
 * @param {string} [params.pageParam="page"] - The query parameter for the page number.
 * @param {string} [params.limitParam="limit"] - The query parameter for the page size.
 * @param {string} [params.requestType="get"] - The HTTP request type ("get" or "post").
 * @param {Object} [params.body] - The request body for POST requests.
 * @param {Object} [params.fetchByIdBody] - The request body for POST requests when fetching by ID.
 * @param {string} [params.searchParam] - The query parameter for search.
 * 
 * @returns {Object} - An object containing the following properties:
 *   - loadOptions: A function to load options for the dropdown.
 *   - loadOptionsById: A function to load options by ID.
 *   - isLoading: A boolean indicating if the data is currently being loaded.
 *   - isSuccess: A boolean indicating if the data was successfully loaded.
 *   - isError: A boolean indicating if there was an error loading the data.
 */
const useDropdownPaginatedApiCall: PaginatedApiCallHookResponseType = ({
  apiUrl,
  fetchByIdUrl = "",
  pageSize = DROPDOWN_PAGE_SIZE,
  paginationType = "offset",
  useBaseUrl = true,
  parser,
  fetchByIdParser,
  pageParam = "page",
  limitParam = "limit",
  requestType = "get",
  body,
  fetchByIdBody,
  searchParam,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const fetchData: ENPaginatedSelectLoadOptionsType = async (search, loadedOptions, prevAdditional) => {
    try {
      setIsError(false)
      setIsLoading(true)
      const page =
        prevAdditional?.page === undefined ? Math.floor(loadedOptions.length / pageSize) + 1 : prevAdditional?.page
      const offset = prevAdditional?.offset === undefined ? loadedOptions.length : prevAdditional?.offset
      const url = `${apiUrl}${apiUrl.includes("?") ? "&" : "?"}${
        paginationType === "page" ? `${pageParam}=${page}` : `offset=${offset}`
      }&${limitParam}=${pageSize}${searchParam && search ? `&${searchParam}=${search}` : ""}`
      const response =
        requestType === "get"
          ? useBaseUrl
            ? await getRequest(url, useBaseUrl)
            : await fetchExternalApi("get", url)
          : useBaseUrl
          ? await postRequest(url, body, {}, useBaseUrl, true)
          : await fetchExternalApi("post", url, body)
      setIsSuccess(true)
      const { hasMore, options, additional } = parser(useBaseUrl ? response.data : response)

      if (search && !hasMore && !searchParam) {
        return {
          hasMore,
          options: options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase())),
          additional,
        }
      }
      return { hasMore, options, additional }
    } catch (error) {
      console.log("error", error)
      setIsError(true)
      return { hasMore: false, options: [] }
    } finally {
      setIsLoading(false)
    }
  }

  const fetchDataById: FetchByIdType = async () => {
    try {
      const response =
        requestType === "get"
          ? useBaseUrl
            ? await getRequest(fetchByIdUrl)
            : await fetchExternalApi("get", fetchByIdUrl)
          : useBaseUrl
          ? await postRequest(fetchByIdUrl, fetchByIdBody, {}, useBaseUrl, true)
          : await fetchExternalApi("post", fetchByIdUrl, fetchByIdBody)
      const parsedData = fetchByIdParser?.(useBaseUrl ? response.data : response)
      return { selectedOptions: parsedData ? parsedData.selectedOptions : [] }
    } catch (error) {
      console.log("error", error)
      return { selectedOptions: [] }
    }
  }
  return { loadOptions: fetchData, loadOptionsById: fetchDataById, isLoading, isSuccess, isError }
}

export default useDropdownPaginatedApiCall
