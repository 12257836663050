import { lazy } from "react"
import AppContainer from "src/components/AppContainer"
import { TabsItemType } from "src/components/TabsView"
import ApplicationPolicyEvaluation from "src/pages/Monitor/ApplicationPolicyEvaluation"
import NetworkPolicyEvaluation from "src/pages/Monitor/NetworkPolicyEvaluation"
import { IconNameType } from "src/shared/components/Icons"
import { FeatureFlagsType } from "src/store/ui/types"
import { ALL_CUSTOMER_ROLES, Roles, RoleValueType } from "./Roles"
const PrivateApplicationsDiscovery = lazy(() => import("src/pages/PrivateApplicationsDiscovery"))
const ActivityLogs = lazy(() => import("src/pages/ActivityLogs"))
const AlreadyLoggedInPage = lazy(() => import("src/pages/AlreadyLoggedInPage"))
const ApplicationDiscovery = lazy(() => import("src/pages/ApplicationDiscovery/ApplicationDiscovery"))
const ApplicationGroups = lazy(() => import("src/pages/ApplicationGroups"))
const AuthorizeAccess = lazy(() => import("src/pages/AuthorizeAccess"))
const AuthorizeService = lazy(() => import("src/pages/AuthorizeService"))
const AWSIntegration = lazy(() => import("src/pages/AWSIntegration"))
const AzureIntegration = lazy(() => import("src/pages/AzureIntegration"))
const AuthConditions = lazy(() => import("src/pages/Conditions/AuthConditions"))
const LocationConditions = lazy(() => import("src/pages/Conditions/LocationConditions"))
const TimeConditions = lazy(() => import("src/pages/Conditions/TimeConditions"))
const CookieErrorPage = lazy(() => import("src/pages/CookieErrorPage"))
const DashboardChart = lazy(() => import("src/pages/DashboardChart"))
const DeviceGroups = lazy(() => import("src/pages/DeviceGroups"))
const DnsPolicies = lazy(() => import("src/pages/DnsPolicies"))
const DnsServers = lazy(() => import("src/pages/DnsServers"))
const Downloads = lazy(() => import("src/pages/Downloads"))
const EndUserApplications = lazy(() => import("src/pages/EndUserApplications"))
const EndUserDevices = lazy(() => import("src/pages/EndUserDevices"))
const EndUserInvite = lazy(() => import("src/pages/EndUserInvite"))
const PreparingPortal = lazy(() => import("src/pages/EndUserServices/PreparingPortal/PreparingPortal"))
const EnteringZTAPage = lazy(() => import("src/pages/EnteringZTAPage"))
const Error403Page = lazy(() => import("src/pages/Error403Page"))
const Error404Page = lazy(() => import("src/pages/Error404Page"))
const ExtremeRelayNodes = lazy(() => import("src/pages/ExtremeAdminRelay"))
const ForgotPassword = lazy(() => import("src/pages/ForgotPassword"))
const GCPIntegration = lazy(() => import("src/pages/GCPIntegration"))
const IconsPage = lazy(() => import("src/pages/IconsPage"))
const IdentitiesHistory = lazy(() => import("src/pages/Identities/IdentitiesHistory/IdentitiesHistory"))
const IdentityProviders = lazy(() => import("src/pages/IdentityProviders"))
const LinkAlreadyUsed = lazy(() => import("src/pages/LinkAlreadyUsed"))
const LinkExpired = lazy(() => import("src/pages/LinkExpired"))
const EndUserLogin = lazy(() => import("src/pages/Login/EndUserLogin"))
const EndUserLoginPOC = lazy(() => import("src/pages/LoginPOC/EndUserLogin"))
const SuperAdminLogin = lazy(() => import("src/pages/Login/SuperAdminLogin"))
const MobileDeviceManagement = lazy(() => import("src/pages/MobileDeviceManagement"))
const NetworkServices = lazy(() => import("src/pages/NetworkServices"))
const NetworkServiceGroups = lazy(() => import("src/pages/NetworkServiceGroups"))
const NewUserSessionRequest = lazy(() => import("src/pages/NewUserSessionRequest"))
const Onboarding = lazy(() => import("src/pages/Onboarding"))
const OpenAppPage = lazy(() => import("src/pages/OpenAppPage"))
const PermissionsUpdatedPage = lazy(() => import("src/pages/PermissionsUpdatedPage"))
const Policies = lazy(() => import("src/pages/Policies/NetworkPolicies"))
const ApplicationPolicies = lazy(() => import("src/pages/Policies/ApplicationPolicies"))
const Profile = lazy(() => import("src/pages/Profile"))
const ResetPassword = lazy(() => import("src/pages/ResetPassword"))
const ServiceAgentlessTunnel = lazy(() => import("src/pages/ServiceAgentlessTunnel"))
const ServiceConnectors = lazy(() => import("src/pages/ServiceConnectors"))
const AllApplications = lazy(() => import("src/pages/Services/AllApplications"))
const Sites = lazy(() => import("src/pages/Sites"))
const SuperAdminIDPs = lazy(() => import("src/pages/SuperAdminIDPs"))
const TenantAdminRecommendation = lazy(() => import("src/pages/TenantAdminRecommendation"))
const Tenants = lazy(() => import("src/pages/Tenants"))
const UnauthorizedLocation = lazy(() => import("src/pages/UnauthorizedLocation"))
const UnauthorizedOSOrBrowserPage = lazy(() => import("src/pages/UnauthorizedOSAndBrowserPage"))
const UserGroups = lazy(() => import("src/pages/UserGroups"))
const Users = lazy(() => import("src/pages/Users"))
const XIQSessionExpirePage = lazy(() => import("src/pages/XIQSessionExpirePage"))

/**
 * CRAAS imports
 */
const CertificateManagement = lazy(() => import("src/craas/pages/CertificateManagement"))
const NetworkDevices = lazy(() => import("src/craas/pages/NetworkDevices/NetworkDevices"))
const RadsecProxy = lazy(() => import("src/craas/pages/RadsecProxy/RadsecProxy"))
const Devices = lazy(() => import("src/craas/pages/Devices/Devices"))
const RadiusServer = lazy(() => import("src/craas/pages/RadiusServers/RadiusServer"))
const Ssid = lazy(() => import("src/craas/pages/Ssid/Ssid"))
const AlreadyLoggedInPageOnInvite = lazy(() => import("src/pages/AlreadyLoggedInPageOnInvite"))
const ChromeBrowserAllowed = lazy(() => import("src/pages/ChromeBrowserAllowed"))
const AddDevicePosture = lazy(() => import("src/pages/DevicePosture/AddDevicePosture"))
const APIBasedLogCollection = lazy(() => import("src/pages/EventCollector/APIBasedLogCollection"))
const SplunkIntegration = lazy(() => import("src/pages/EventCollector/SplunkIntegration"))
const Identities = lazy(() => import("src/pages/Identities"))
const Alerts = lazy(() => import("src/pages/Monitor/Alerts/Alerts"))
const Subscriptions = lazy(() => import("src/pages/Monitor/Subscriptions"))
const RadiusTemplate = lazy(() => import("src/craas/pages/RadiusTemplate"))

export const ROOT_PATH = "/"
/**
 * The below are Auth Routes and will only contain the authentication based routes like login, signup etc
 */
export const ZTNA_APP_BASE_ROUTE = "app"
export const ZTNA_ADMIN_BASE_ROUTE = "console"
export const AuthRoutesMap = {
  LOGIN: { pathId: "login", absolutePath: "/login" },
  LOGINPOC: { pathId: "login-poc", absolutePath: "/login-poc" },
  ENTERING_ZTA: { pathId: "zta", absolutePath: "/zta" },
  REGISTER: { pathId: "register", absolutePath: "/register" },
  FORGOT_PASSWORD: { pathId: "forgot-password", absolutePath: "/forgot-password" },
  RESET_PASSWORD: { pathId: "set-password", absolutePath: "/set-password" },
  USER_INVITE: { pathId: "user-invite", absolutePath: "/user-invite" },
  ZTNA_ADMIN_LOGIN: { pathId: `${ZTNA_ADMIN_BASE_ROUTE}/login`, absolutePath: `/${ZTNA_ADMIN_BASE_ROUTE}/login` },
}

/**
 * Below are public routes that are accessible across the application
 */
export const PublicRoutesMap = {
  FORBIDDEN_PAGE: { pathId: "forbidden-page", absolutePath: "/forbidden-page" },
  NOT_FOUND_PAGE: { pathId: "not-found-page", absolutePath: "/not-found-page" },
  COOKIE_ERROR_PAGE: { pathId: "cookies-disabled", absolutePath: "/cookies-disabled" },
  UNAUTHORIZED_OS_OR_BROWSER: { pathId: "unauthorized-os-browser", absolutePath: "/unauthorized-os-browser" },
  UNAUTHORIZED_LOCATION: { pathId: "unauthorized-location", absolutePath: "/unauthorized-location" },
  OPEN_APP: { pathId: "open-zta-app", absolutePath: "/open-zta-app" },
  ALREADY_LOGGED_IN: { pathId: "already-logged-in", absolutePath: "/already-logged-in" },
  ALREADY_LOGGED_IN_ON_INVITE: { pathId: "already-logged-in-on-invite", absolutePath: "/already-logged-in-on-invite" },
  AUTHORIZE_ACCESS: { pathId: "authorize-access", absolutePath: "/authorize-access" },
  LINK_EXPIRED: { pathId: "link-expired", absolutePath: "/link-expired" },
  LINK_ALREADY_USED: { pathId: "link-already-used", absolutePath: "/link-already-used" },
  PERMISSIONS_UPDATED: { pathId: "permissions-updated", absolutePath: "/permissions-updated" },
  TENANT_ADMIN_RECOMMENDATION: { pathId: "tenant-admin-recommendation", absolutePath: "/tenant-admin-recommendation" },
  EXISTING_USER_SESSION: { pathId: "new-user-session-request", absolutePath: "/new-user-session-request" },
  XIQ_SESSION_EXPIRE: { pathId: "xiq-session-expire", absolutePath: "/xiq-session-expire" },
  ICONS: { pathId: "icons", absolutePath: "/icons" },
  PREPARE_PORTAL: { pathId: "preparing-portal", absolutePath: "/preparing-portal" },
  CHROME_BROWSER_ALLOWED: { pathId: "chrome-browser-allowed", absolutePath: "/chrome-browser-allowed" },
  AUTHENTICATE_SERVICE_ACCESS: {
    pathId: `services/authenticate-service-access`,
    absolutePath: `/services/authenticate-service-access`,
  },
  SERVICE_AGENTLESS_TUNNEL: {
    pathId: "service/agentless-tunnel",
    absolutePath: `/service/agentless-tunnel`,
  },
}

/**
 * Below are app specific private routes that are accessible only when user is authenticated
 */

export const END_USER_BASE_ROUTE_PREFIX = "enduser-portal"
export const PrivateRoutesMap = {
  USERS: { pathId: "users", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/users` },
  AGENTS: { pathId: "agents", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/agents` },
  DEVICES: { pathId: "devices", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/devices` },
  DEVICE_POSTURE: { pathId: "device-posture", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/device-posture` },
  DISCOVERY_APPS: { pathId: "discovery-apps", absolutePath: `/discovery-apps` },
  PROFILE: { pathId: "profile", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/profile` },
  USER_GROUPS: { pathId: "user-groups", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/user-groups` },
  DEVICE_GROUPS: { pathId: "device-groups", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/device-groups` },
  TENANT_IDENTITY_PROVIDERS: {
    pathId: "tenant-identity-providers",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/tenant-identity-providers`,
  },
  GLOBAL_IDENTITY_PROVIDERS: {
    pathId: "global-identity-providers",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/global-identity-providers`,
  },
  GLOBAL_IDENTITY_PROVIDERS_MICROSOFT: {
    pathId: "global-identity-providers-microsoft",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/global-identity-providers-microsoft`,
  },
  LICENSING: { pathId: "licensing", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/licensing` },
  RELAY_NODES: { pathId: "relay-nodes", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/relay-nodes` },
  SITES: { pathId: "sites", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/sites` },
  CONNECTORS: { pathId: "service-connectors", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/service-connectors` },
  RADIUS_SERVERS: { pathId: "radius-servers", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/radius-servers` },
  SSID: { pathId: "ssid", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/ssid` },
  // RADIUS_SERVERS: { pathId: "radius-server", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/radius-server` },
  NETWORK_DEVICES: { pathId: "network-devices", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/network-devices` },
  RADIUS_TEMPLATE: { pathId: "radius-template", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/radius-template` },
  CERTIFICATE_MANAGEMENT: {
    pathId: "certificate-management",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/certificate-management`,
  },
  CONFIGURATIONS: { pathId: "configurations", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/configurations` },
  ALL_SERVICES: { pathId: "private", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/private` },
  PUBLIC_SAAS: { pathId: "saas", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/saas` },
  APPLICATION_GROUPS: { pathId: "application-groups", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/application-groups` },
  POLICIES: { pathId: "policies", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/policies` },
  APPLICATION_POLICIES: {
    pathId: "application-policies",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/application-policies`,
  },
  DNS_SERVERS: { pathId: "dns-servers", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/dns-servers` },
  DNS_POLICIES: { pathId: "dns-policies", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/dns-policies` },
  LOCATION_BASED_CONDITIONS: {
    pathId: "location-based-conditions",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/location-based-conditions`,
  },
  TIME_BASED_CONDITIONS: {
    pathId: "time-based-conditions",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/time-based-conditions`,
  },
  AUTH_BASED_CONDITIONS: {
    pathId: "auth-based-conditions",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/auth-based-conditions`,
  },
  NETWORK: { pathId: "network", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/network` },
  NETWORK_GROUPS: { pathId: "network-groups", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/network-groups` },
  AWS_INTEGRATIONS: { pathId: "aws-integrations", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/aws-integrations` },
  AZURE_INTEGRATIONS: { pathId: "azure-integrations", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/azure-integrations` },
  GCP_INTEGRATIONS: { pathId: "gcp-integrations", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/gcp-integrations` },
  ACTIVITY_LOGS: { pathId: "activity-logs", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/activity-logs` },
  EVENT_COLLECTOR: { pathId: "event-collector", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/event-collector` },
  MOBILE_DEVICE_MANAGEMENT: {
    pathId: "mobile-device-management",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/mobile-device-management`,
  },
  MS_INTUNE_INTEGRATION: {
    pathId: "ms-intune-management",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/ms-intune-management`,
  },
  MONITOR: {
    pathId: "monitor",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/monitor`,
  },
  TROUBLESHOOTING: { pathId: "troubleshooting", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/troubleshooting` },
  NETWORK_POLICY_EVALUATION: {
    pathId: "network-policy-evaluation",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/network-policy-evaluation`,
  },
  APPLICATION_POLICY_EVALUATION: {
    pathId: "application-policy-evaluation",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/application-policy-evaluation`,
  },
  ALERTS: { pathId: "alerts", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/alerts` },
  SUBSCRIPTIONS: { pathId: "subscriptions", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/subscriptions` },
  SETTINGS: {
    pathId: "settings",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/settings`,
  },
  BILLING_AND_LICENSING: {
    pathId: "billing-and-licensing",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/billing-and-licensing`,
  },
  ONBOARDING: { pathId: "onboarding", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/onboarding` },
  INSIGHTS: { pathId: "insights", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/insights` },
  APPLICATION_DISCOVERY: { pathId: "discovery", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/discovery` },
  PRIVATE_APPLICATIONS_DISCOVERY: {
    pathId: "private-app-discovery",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/private-app-discovery`,
  },
  IDENTITIES: { pathId: "identities", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/identities` },
  IDENTITIES_HISTORY: { pathId: "identities-history", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/identities-history` },
  DASHBOARD_CHART: { pathId: "dashboardchart", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/dashboardchart` },
  DASHBOARD: { pathId: "dashboard", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/dashboard` },
  PROFILE_COMPLETION: { pathId: "profile-completion", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/profile-completion` },
  TENANTS: { pathId: "tenants", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/tenants` },
  CLIENTS: { pathId: "clients", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/clients` },
  MULTI_TENANTS_RELAY: { pathId: "relays", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/relays` },
  DRAP_LOAD_BALANCERS: { pathId: "drap-load-balancers", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/drap-load-balancers` },
  DRAP_NODES: { pathId: "drap-nodes", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/drap-nodes` },

  /** End User Routes */
  END_USER_FEEDBACK: {
    pathId: `${END_USER_BASE_ROUTE_PREFIX}/feedback`,
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/${END_USER_BASE_ROUTE_PREFIX}/feedback`,
  },
  END_USER_SERVICES: {
    pathId: `${END_USER_BASE_ROUTE_PREFIX}/services`,
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/${END_USER_BASE_ROUTE_PREFIX}/services`,
  },
  END_USER_APPLICATIONS: {
    pathId: `${END_USER_BASE_ROUTE_PREFIX}/applications`,
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/${END_USER_BASE_ROUTE_PREFIX}/applications`,
  },
  END_USER_DEVICES: {
    pathId: `${END_USER_BASE_ROUTE_PREFIX}/user-devices`,
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/${END_USER_BASE_ROUTE_PREFIX}/user-devices`,
  },
  END_USER_DOWNLOADS: {
    pathId: `${END_USER_BASE_ROUTE_PREFIX}/user-downloads`,
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/${END_USER_BASE_ROUTE_PREFIX}/user-downloads`,
  },
  /**
   * CRAAS routes
   */
  XIQ_INTEGRATION: {
    pathId: "xiq-integration",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/xiq-integration`,
  },
  NETWORK_POLICIES: {
    pathId: "network-policies",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/network-policies`,
  },
  RADSEC_PROXY: { pathId: "radsec-proxy", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/radsec-proxy` },
  RADSEC_RADIUS_SERVERS: {
    pathId: "radsec-radius-servers",
    absolutePath: `/${ZTNA_APP_BASE_ROUTE}/radsec-radius-servers`,
  },
  NETWORK_SITES: { pathId: "network-sites", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/network-sites` },
  SPLUNK_INTEGRATION: { pathId: "splunk-integration", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/splunk-integration` },
  API_LOG_COLLECTION: { pathId: "api-log-collection", absolutePath: `/${ZTNA_APP_BASE_ROUTE}/api-log-collection` },
}

export type RouteType = {
  id: string
  component?: React.ElementType
  path?: { absolutePath: string; pathId: string }
  redirectForAuthenticatedUser?: boolean
  title?: string
  iconName?: IconNameType
  isRelativeRoute?: boolean
  permission: (RoleValueType | "public")[]
  childs?: RouteType[]
  tabs?: TabsItemType[]
  showInSideNavigation: boolean
  isDataGrid?: boolean
}

type TabType = { id: number; tabLabel: string; route: string }

export type RouteWithoutComponentType = Omit<RouteType, "component"> & { childs?: RouteWithoutComponentType[] }

const applicationsTabs = (): TabType[] => [
  { id: 0, tabLabel: "Private Hosted Applications", route: PrivateRoutesMap.ALL_SERVICES.absolutePath },
  // ...(publicSaas ? [{ id: 1, tabLabel: "SaaS Applications", route: PrivateRoutesMap.PUBLIC_SAAS.absolutePath }] : []),
]

const integrationsTabs: TabType[] = [
  { id: 0, tabLabel: "AWS Integration", route: PrivateRoutesMap.AWS_INTEGRATIONS.absolutePath },
  { id: 1, tabLabel: "Azure Integration", route: PrivateRoutesMap.AZURE_INTEGRATIONS.absolutePath },
  { id: 2, tabLabel: "GCP Integration", route: PrivateRoutesMap.GCP_INTEGRATIONS.absolutePath },
]

const AzureintegrationsTabs: TabType[] = [
  { id: 0, tabLabel: "Azure Integration", route: PrivateRoutesMap.AZURE_INTEGRATIONS.absolutePath },
  { id: 1, tabLabel: "GCP Integration", route: PrivateRoutesMap.GCP_INTEGRATIONS.absolutePath },
]

const deviceManagementTabs: TabType[] = [
  { id: 0, tabLabel: "Microsoft Intune", route: PrivateRoutesMap.MS_INTUNE_INTEGRATION.absolutePath },
]

const eventCollectorTabs: TabType[] = [
  { id: 0, tabLabel: "Splunk", route: PrivateRoutesMap.SPLUNK_INTEGRATION.absolutePath },
  { id: 1, tabLabel: "API-Based Log Collection", route: PrivateRoutesMap.API_LOG_COLLECTION.absolutePath },
]

const networkResourcesTabs: TabType[] = [
  { id: 0, tabLabel: "Network Devices", route: PrivateRoutesMap.NETWORK_DEVICES.absolutePath },
  { id: 1, tabLabel: "SSID", route: PrivateRoutesMap.SSID.absolutePath },
  { id: 2, tabLabel: "RADIUS Template", route: PrivateRoutesMap.RADIUS_TEMPLATE.absolutePath },
]

const troubleshootingTabs: TabType[] = [
  { id: 0, tabLabel: "Network Policy Evaluation", route: PrivateRoutesMap.NETWORK_POLICY_EVALUATION.absolutePath },
  {
    id: 1,
    tabLabel: "Application Policy Evaluation",
    route: PrivateRoutesMap.APPLICATION_POLICY_EVALUATION.absolutePath,
  },
]

const identityProviderTabs: TabType[] = [
  { id: 0, tabLabel: "Google Workspace", route: PrivateRoutesMap.GLOBAL_IDENTITY_PROVIDERS.absolutePath },
  { id: 1, tabLabel: "Microsoft Entra ID", route: PrivateRoutesMap.GLOBAL_IDENTITY_PROVIDERS_MICROSOFT.absolutePath },
]

const conditionsTabs = (enableCraas: boolean | undefined): TabType[] => {
  return [
    { id: 0, tabLabel: "Location-Based Conditions", route: PrivateRoutesMap.LOCATION_BASED_CONDITIONS.absolutePath },
    { id: 1, tabLabel: "Time-Based Conditions", route: PrivateRoutesMap.TIME_BASED_CONDITIONS.absolutePath },
    ...(enableCraas
      ? [
          {
            id: 2,
            tabLabel: "Authentication-Based Conditions",
            route: PrivateRoutesMap.AUTH_BASED_CONDITIONS.absolutePath,
          },
        ]
      : []),
  ]
}

const dnsTabs: TabType[] = [
  { id: 0, tabLabel: "DNS Servers", route: PrivateRoutesMap.DNS_SERVERS.absolutePath },
  { id: 1, tabLabel: "DNS Policies", route: PrivateRoutesMap.DNS_POLICIES.absolutePath },
]

const policiesTabs: TabType[] = [
  { id: 0, tabLabel: "Network/Hybrid Policies", route: PrivateRoutesMap.POLICIES.absolutePath },
  { id: 1, tabLabel: "Application Policies", route: PrivateRoutesMap.APPLICATION_POLICIES.absolutePath },
]

export const RouteConfig = (
  {
    agentlessSoftRemoval: isAgentlessSoftRemoved,
    enableCraas,
    publicSaas,
    devicePosture,
    appDiscovery,
  }: FeatureFlagsType,
  role?: string,
): RouteType[] => {
  const isAWSEnv = localStorage.getItem("cloudHost") !== "GCP" && localStorage.getItem("cloudHost") !== "AZURE"

  return [
    {
      id: "root--auth",
      showInSideNavigation: false,
      permission: ["public"],
      childs: [
        {
          id: "auth--login",
          showInSideNavigation: false,
          component: EndUserLogin,
          isRelativeRoute: true,
          path: AuthRoutesMap.LOGIN,
          redirectForAuthenticatedUser:
            (role === Roles.SUPER_ADMIN ||
              role === Roles.END_USER ||
              role === Roles.CUSTOMER_MONITOR_ADMIN ||
              role === Roles.CUSTOMER_OBSERVER_ADMIN) &&
            !window.location.pathname.includes("user-email")
              ? true
              : false,
          permission: ["public"],
        },
        {
          id: "auth--login--poc",
          showInSideNavigation: false,
          component: EndUserLoginPOC,
          isRelativeRoute: true,
          path: AuthRoutesMap.LOGINPOC,
          redirectForAuthenticatedUser: role === Roles.SUPER_ADMIN || role === Roles.END_USER ? true : false,
          permission: ["public"],
        },
        {
          id: "auth--zta",
          showInSideNavigation: false,
          component: EnteringZTAPage,
          isRelativeRoute: true,
          path: AuthRoutesMap.ENTERING_ZTA,
          redirectForAuthenticatedUser: false,
          permission: ["public"],
        },
        {
          id: "auth--ztna-admin-login",
          showInSideNavigation: false,
          component: SuperAdminLogin,
          path: AuthRoutesMap.ZTNA_ADMIN_LOGIN,
          redirectForAuthenticatedUser: true,
          permission: ["public"],
        },
        {
          id: "auth--ztna-admin-forgot-password",
          showInSideNavigation: false,
          component: ForgotPassword,
          redirectForAuthenticatedUser: true,
          path: AuthRoutesMap.FORGOT_PASSWORD,
          permission: ["public"],
        },
        {
          id: "auth--ztna-admin-set-password",
          showInSideNavigation: false,
          component: ResetPassword,
          isRelativeRoute: true,
          path: AuthRoutesMap.RESET_PASSWORD,
          permission: ["public"],
        },
        {
          id: "auth--user-invite",
          showInSideNavigation: false,
          component: EndUserInvite,
          isRelativeRoute: true,
          path: AuthRoutesMap.USER_INVITE,
          permission: ["public"],
        },
      ],
    },
    {
      id: "root--forbidden-page",
      showInSideNavigation: false,
      component: Error403Page,
      path: PublicRoutesMap.FORBIDDEN_PAGE,
      permission: ["public"],
    },
    {
      id: "service-agentless-tunnel",
      component: ServiceAgentlessTunnel,
      path: {
        pathId: `${PublicRoutesMap.SERVICE_AGENTLESS_TUNNEL.pathId}/:serviceId`,
        absolutePath: `${PublicRoutesMap.SERVICE_AGENTLESS_TUNNEL.absolutePath}/:serviceId`,
      },
      permission: ["public"],
      showInSideNavigation: false,
    },
    {
      id: "authenticate-service-access-root",
      component: AuthorizeService,
      path: PublicRoutesMap.AUTHENTICATE_SERVICE_ACCESS,
      permission: ["public"],
      showInSideNavigation: false,
    },
    {
      id: "root--chrome-browser-allowed-page",
      showInSideNavigation: false,
      component: ChromeBrowserAllowed,
      path: PublicRoutesMap.CHROME_BROWSER_ALLOWED,
      permission: ["public"],
    },
    {
      id: "root--not-found-page",
      showInSideNavigation: false,
      component: Error404Page,
      path: PublicRoutesMap.NOT_FOUND_PAGE,
      permission: ["public"],
    },
    {
      id: "root--cookies-disabled",
      showInSideNavigation: false,
      component: CookieErrorPage,
      path: PublicRoutesMap.COOKIE_ERROR_PAGE,
      permission: ["public"],
    },
    {
      id: "root--unauthorized-posture",
      showInSideNavigation: false,
      component: UnauthorizedOSOrBrowserPage,
      path: PublicRoutesMap.UNAUTHORIZED_OS_OR_BROWSER,
      permission: ["public"],
    },
    {
      id: "root--unauthorized-location",
      showInSideNavigation: false,
      component: UnauthorizedLocation,
      path: PublicRoutesMap.UNAUTHORIZED_LOCATION,
      permission: [Roles.END_USER],
    },
    {
      id: "root--open-app-page",
      showInSideNavigation: false,
      component: OpenAppPage,
      path: PublicRoutesMap.OPEN_APP,
      permission: ["public"],
    },
    {
      id: "root--already-logged-in",
      showInSideNavigation: false,
      component: AlreadyLoggedInPage,
      isRelativeRoute: true,
      path: PublicRoutesMap.ALREADY_LOGGED_IN,
      permission: ["public"],
    },
    {
      id: "root--already-logged-in-on-invite",
      showInSideNavigation: false,
      component: AlreadyLoggedInPageOnInvite,
      isRelativeRoute: true,
      path: PublicRoutesMap.ALREADY_LOGGED_IN_ON_INVITE,
      permission: ["public"],
    },
    {
      id: "root--authorize-page",
      showInSideNavigation: false,
      isRelativeRoute: true,
      component: AuthorizeAccess,
      path: PublicRoutesMap.AUTHORIZE_ACCESS,
      permission: ["public"],
    },
    {
      id: "root--link-expired",
      showInSideNavigation: false,
      component: LinkExpired,
      path: PublicRoutesMap.LINK_EXPIRED,
      permission: ["public"],
    },
    {
      id: "root--link-already-used",
      showInSideNavigation: false,
      component: LinkAlreadyUsed,
      path: PublicRoutesMap.LINK_ALREADY_USED,
      permission: ["public"],
    },
    {
      id: "root--permissions-updated",
      showInSideNavigation: false,
      component: PermissionsUpdatedPage,
      path: PublicRoutesMap.PERMISSIONS_UPDATED,
      permission: ["public"],
    },
    {
      id: "root--icons",
      showInSideNavigation: false,
      component: IconsPage,
      path: PublicRoutesMap.ICONS,
      permission: ["public"],
    },
    {
      id: "root--tenant-admin-recommendation",
      showInSideNavigation: false,
      component: TenantAdminRecommendation,
      path: PublicRoutesMap.TENANT_ADMIN_RECOMMENDATION,
      permission: ["public"],
    },
    {
      id: "end-user-root--preparing-portal",
      showInSideNavigation: false,
      component: PreparingPortal,
      path: PublicRoutesMap.PREPARE_PORTAL,
      permission: ["public"],
    },
    {
      id: "root--new-user-session-request",
      showInSideNavigation: false,
      component: NewUserSessionRequest,
      path: PublicRoutesMap.EXISTING_USER_SESSION,
      permission: ["public"],
    },
    {
      id: "root--xiq-session-expire",
      showInSideNavigation: false,
      component: XIQSessionExpirePage,
      path: PublicRoutesMap.XIQ_SESSION_EXPIRE,
      permission: ["public"],
    },
    {
      id: "root--ztna-app",
      showInSideNavigation: true,
      component: AppContainer,
      path: { pathId: ZTNA_APP_BASE_ROUTE, absolutePath: ZTNA_APP_BASE_ROUTE },
      permission: [
        Roles.CUSTOMER_SUPER_ADMIN,
        Roles.CUSTOMER_MONITOR_ADMIN,
        Roles.CUSTOMER_OBSERVER_ADMIN,
        Roles.END_USER,
        Roles.SUPER_ADMIN,
      ],
      childs: [
        {
          id: "onboarding-root",
          component: Onboarding,
          path: PrivateRoutesMap.ONBOARDING,
          showInSideNavigation: true,
          title: "Onboarding",
          iconName: "pointer",
          permission: [Roles.CUSTOMER_SUPER_ADMIN],
        },

        {
          id: "dashboard-root",
          showInSideNavigation: true,
          title: "Insights", // previously named Dashboard
          permission: ALL_CUSTOMER_ROLES,
          iconName: "chart",
          childs: [
            {
              id: "dashboard--dashboard-chart",
              component: DashboardChart,
              showInSideNavigation: true,
              isRelativeRoute: true,
              title: "Dashboard",
              path: PrivateRoutesMap.DASHBOARD_CHART,
              permission: ALL_CUSTOMER_ROLES,
            },
            {
              id: "insights--identities",
              component: Identities,
              showInSideNavigation: true,
              isRelativeRoute: true,
              isDataGrid: true,
              title: "Identities",
              path: PrivateRoutesMap.IDENTITIES,
              permission: ALL_CUSTOMER_ROLES,
            },
            {
              id: "insights--private-app-discovery",
              component: PrivateApplicationsDiscovery,
              showInSideNavigation: true,
              title: "Application Discovery",
              isRelativeRoute: true,
              path: PrivateRoutesMap.PRIVATE_APPLICATIONS_DISCOVERY,
              permission: [Roles.CUSTOMER_SUPER_ADMIN],
            },
            {
              id: "insights--identities-history",
              component: IdentitiesHistory,
              showInSideNavigation: false,
              isRelativeRoute: true,
              title: "Identities history",
              path: PrivateRoutesMap.IDENTITIES_HISTORY,
              permission: ALL_CUSTOMER_ROLES,
            },
            ...(appDiscovery
              ? [
                  {
                    id: "insights--app-discovery",
                    component: ApplicationDiscovery,
                    showInSideNavigation: true,
                    title: "Application Discovery",
                    isRelativeRoute: true,
                    path: PrivateRoutesMap.APPLICATION_DISCOVERY,
                    permission: ALL_CUSTOMER_ROLES,
                  },
                ]
              : []),
          ],
        },
        {
          id: "iam-root",
          title: "IAM",
          showInSideNavigation: true,
          iconName: "users",
          permission: ALL_CUSTOMER_ROLES,
          childs: [
            {
              id: "iam--users",
              component: Users,
              showInSideNavigation: true,
              title: "Users",
              isRelativeRoute: true,
              isDataGrid: true,
              path: PrivateRoutesMap.USERS,
              permission: ALL_CUSTOMER_ROLES,
            },
            {
              id: "iam--user-groups",
              component: UserGroups,
              showInSideNavigation: true,
              isRelativeRoute: true,
              isDataGrid: true,
              title: "User Groups",
              path: PrivateRoutesMap.USER_GROUPS,
              permission: ALL_CUSTOMER_ROLES,
            },
            ...(enableCraas
              ? [
                  {
                    id: "iam--devices",
                    component: Devices,
                    showInSideNavigation: true,
                    title: "Devices",
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.DEVICES,
                    permission: ALL_CUSTOMER_ROLES,
                  },
                  {
                    id: "iam--device-groups",
                    component: DeviceGroups,
                    showInSideNavigation: true,
                    isRelativeRoute: true,
                    isDataGrid: true,
                    title: "Device Groups",
                    path: PrivateRoutesMap.DEVICE_GROUPS,
                    permission: ALL_CUSTOMER_ROLES,
                  },
                ]
              : []),

            {
              id: "iam--identity-providers",
              component: IdentityProviders,
              isRelativeRoute: true,
              showInSideNavigation: true,
              title: "Identity Providers",
              path: PrivateRoutesMap.TENANT_IDENTITY_PROVIDERS,
              permission: ALL_CUSTOMER_ROLES,
            },
          ],
        },

        {
          id: "resources-root",
          title: "Resources",
          showInSideNavigation: true,
          permission: [Roles.CUSTOMER_SUPER_ADMIN],
          iconName: "resources2",
          childs: [
            {
              id: "resources--sites",
              component: Sites,
              showInSideNavigation: true,
              title: "Sites",
              isRelativeRoute: true,
              isDataGrid: true,
              path: PrivateRoutesMap.SITES,
              permission: ALL_CUSTOMER_ROLES,
            },
            ...(enableCraas
              ? [
                  {
                    id: "resources--secure-proxy",
                    component: RadsecProxy,
                    title: "RadSec Proxy",
                    showInSideNavigation: true,
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.RADSEC_PROXY,
                    permission: ALL_CUSTOMER_ROLES,
                  },
                ]
              : []),
            {
              id: "resources--service-connectors",
              component: ServiceConnectors,
              title: "Service Connectors",
              showInSideNavigation: true,
              isRelativeRoute: true,
              isDataGrid: true,
              path: PrivateRoutesMap.CONNECTORS,
              permission: ALL_CUSTOMER_ROLES,
            },
            ...(enableCraas
              ? [
                  {
                    id: "resources--radius-server",
                    component: RadiusServer,
                    title: "RADIUS Server", // previously named Available Radius Servers
                    showInSideNavigation: true,
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.RADIUS_SERVERS,
                    permission: ALL_CUSTOMER_ROLES,
                  },
                  {
                    id: "resources--network-devices",
                    component: NetworkDevices,
                    showInSideNavigation: true,
                    title: "Network Resources",
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.NETWORK_DEVICES,
                    permission: ALL_CUSTOMER_ROLES,
                    tabs: networkResourcesTabs,
                  },
                  {
                    id: "resources--ssid",
                    component: Ssid,
                    showInSideNavigation: false,
                    title: "SSID",
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.SSID,
                    permission: ALL_CUSTOMER_ROLES,
                    tabs: networkResourcesTabs,
                  },
                  {
                    id: "resources--radius-template",
                    component: RadiusTemplate,
                    showInSideNavigation: false,
                    title: "RADIUS Template",
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.RADIUS_TEMPLATE,
                    permission: ALL_CUSTOMER_ROLES,
                    tabs: networkResourcesTabs,
                  },
                  {
                    id: "resources-management-root",
                    title: "Certificate Management",
                    component: CertificateManagement,
                    showInSideNavigation: true,
                    isRelativeRoute: true,
                    path: PrivateRoutesMap.CERTIFICATE_MANAGEMENT,
                    permission: [Roles.CUSTOMER_SUPER_ADMIN],
                  },

                  {
                    id: "policies--dns-servers",
                    component: DnsServers,
                    path: PrivateRoutesMap.DNS_SERVERS,
                    showInSideNavigation: true,
                    isDataGrid: true,
                    title: "DNS",
                    permission: ALL_CUSTOMER_ROLES,
                    tabs: dnsTabs,
                  },
                  {
                    id: "policies--dns-policies",
                    component: DnsPolicies,
                    path: PrivateRoutesMap.DNS_POLICIES,
                    showInSideNavigation: false,
                    isDataGrid: true,
                    permission: ALL_CUSTOMER_ROLES,
                    tabs: dnsTabs,
                  },
                ]
              : []),
          ],
        },
        {
          id: "resources-root",
          title: "Resources",
          showInSideNavigation: true,
          permission: [Roles.CUSTOMER_MONITOR_ADMIN, Roles.CUSTOMER_OBSERVER_ADMIN],
          iconName: "resources2",
          childs: [
            {
              id: "resources--sites",
              component: Sites,
              showInSideNavigation: true,
              title: "Sites",
              isRelativeRoute: true,
              isDataGrid: true,
              path: PrivateRoutesMap.SITES,
              permission: ALL_CUSTOMER_ROLES,
            },
            ...(enableCraas
              ? [
                  {
                    id: "resources--secure-proxy",
                    component: RadsecProxy,
                    title: "RadSec Proxy",
                    showInSideNavigation: true,
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.RADSEC_PROXY,
                    permission: ALL_CUSTOMER_ROLES,
                  },
                ]
              : []),
            {
              id: "resources--service-connectors",
              component: ServiceConnectors,
              title: "Service Connectors",
              showInSideNavigation: true,
              isRelativeRoute: true,
              isDataGrid: true,
              path: PrivateRoutesMap.CONNECTORS,
              permission: ALL_CUSTOMER_ROLES,
            },
            ...(enableCraas
              ? [
                  {
                    id: "resources--radius-server",
                    component: RadiusServer,
                    title: "RADIUS Server", // previously named Available Radius Servers
                    showInSideNavigation: true,
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.RADIUS_SERVERS,
                    permission: ALL_CUSTOMER_ROLES,
                  },
                  {
                    id: "resources--network-devices",
                    component: NetworkDevices,
                    showInSideNavigation: true,
                    title: "Network Resources",
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.NETWORK_DEVICES,
                    permission: ALL_CUSTOMER_ROLES,
                    tabs: networkResourcesTabs,
                  },
                  {
                    id: "resources--ssid",
                    component: Ssid,
                    showInSideNavigation: false,
                    title: "SSID",
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.SSID,
                    permission: ALL_CUSTOMER_ROLES,
                    tabs: networkResourcesTabs,
                  },
                  {
                    id: "resources--radius-template",
                    component: RadiusTemplate,
                    showInSideNavigation: false,
                    title: "RADIUS Template",
                    isRelativeRoute: true,
                    isDataGrid: true,
                    path: PrivateRoutesMap.RADIUS_TEMPLATE,
                    permission: ALL_CUSTOMER_ROLES,
                    tabs: networkResourcesTabs,
                  },
                ]
              : []),
          ],
        },
        {
          id: "applications-root",
          title: "Applications",
          showInSideNavigation: true,
          permission: ALL_CUSTOMER_ROLES,
          iconName: "applications",
          childs: [
            {
              id: "applications--all",
              component: AllApplications,
              showInSideNavigation: true,
              title: "Applications",
              isRelativeRoute: true,
              isDataGrid: true,
              path: PrivateRoutesMap.ALL_SERVICES,
              permission: ALL_CUSTOMER_ROLES,
              tabs: applicationsTabs(),
            },
            // ...(publicSaas
            //   ? [
            //       {
            //         id: "applications--public-saas",
            //         component: PublicSaas,
            //         showInSideNavigation: false,
            //         path: PrivateRoutesMap.PUBLIC_SAAS,
            //         permission: ALL_CUSTOMER_ROLES,
            //         tabs: applicationsTabs({ publicSaas }),
            //       },
            //     ]
            //   : []),
            {
              id: "applications--application-groups",
              component: ApplicationGroups,
              title: "Application Groups",
              isRelativeRoute: true,
              showInSideNavigation: true,
              isDataGrid: true,
              path: PrivateRoutesMap.APPLICATION_GROUPS,
              permission: ALL_CUSTOMER_ROLES,
            },
          ],
        },

        ...(enableCraas
          ? ([
              {
                id: "networks-root",
                title: "Network Services",
                showInSideNavigation: true,
                permission: ALL_CUSTOMER_ROLES,
                iconName: "networks",
                childs: [
                  {
                    id: "networks--network",
                    component: NetworkServices,
                    path: PrivateRoutesMap.NETWORK,
                    showInSideNavigation: true,
                    isDataGrid: true,
                    title: "Network Services",
                    permission: ALL_CUSTOMER_ROLES,
                  },
                  {
                    id: "networks--network-groups",
                    component: NetworkServiceGroups,
                    path: PrivateRoutesMap.NETWORK_GROUPS,
                    showInSideNavigation: true,
                    isDataGrid: true,
                    title: "Network Service Groups",
                    permission: ALL_CUSTOMER_ROLES,
                  },
                ],
              },
            ] as RouteType[])
          : []),

        {
          id: "policies-root",
          title: "Policies",
          showInSideNavigation: true,
          permission: ALL_CUSTOMER_ROLES,
          iconName: "policies2",
          childs: [
            {
              id: "policies--network-policies",
              component: Policies,
              path: PrivateRoutesMap.POLICIES,
              showInSideNavigation: true,
              isDataGrid: true,
              title: "Policies",
              permission: ALL_CUSTOMER_ROLES,
              tabs: policiesTabs,
            },
            {
              id: "policies--application-policies",
              component: ApplicationPolicies,
              path: PrivateRoutesMap.APPLICATION_POLICIES,
              showInSideNavigation: false,
              isDataGrid: true,
              permission: ALL_CUSTOMER_ROLES,
              tabs: policiesTabs,
            },
            {
              id: "policies--application-location-based-conditions",
              component: LocationConditions,
              path: PrivateRoutesMap.LOCATION_BASED_CONDITIONS,
              showInSideNavigation: true,
              isDataGrid: true,
              title: "Conditions",
              permission: ALL_CUSTOMER_ROLES,
              tabs: conditionsTabs(enableCraas),
            },
            {
              id: "policies--application-time-based-conditions",
              component: TimeConditions,
              path: PrivateRoutesMap.TIME_BASED_CONDITIONS,
              showInSideNavigation: false,
              isDataGrid: true,
              permission: ALL_CUSTOMER_ROLES,
              tabs: conditionsTabs(enableCraas),
              title: "Time-Based Conditions",
            },
            {
              id: "policies--application-auth-based-conditions",
              component: AuthConditions,
              path: PrivateRoutesMap.AUTH_BASED_CONDITIONS,
              showInSideNavigation: false,
              isDataGrid: true,
              permission: ALL_CUSTOMER_ROLES,
              tabs: conditionsTabs(enableCraas),
              title: "Authentication-Based Conditions",
            },
            ...(devicePosture
              ? ([
                  {
                    id: "policies--application-device-posture",
                    component: AddDevicePosture,
                    path: PrivateRoutesMap.DEVICE_POSTURE,
                    showInSideNavigation: true,
                    title: "Device Posture",
                    permission: ALL_CUSTOMER_ROLES,
                  },
                ] as RouteType[])
              : []),
          ],
        },
        {
          id: "integrations-root",
          title: "Integrations",
          showInSideNavigation: true,
          permission: ALL_CUSTOMER_ROLES,
          iconName: "integrations",
          childs: [
            ...(isAWSEnv
              ? [
                  {
                    id: "integrations--aws",
                    component: AWSIntegration,
                    path: PrivateRoutesMap.AWS_INTEGRATIONS,
                    showInSideNavigation: true,
                    isDataGrid: true,
                    title: "Public Cloud",
                    permission: ALL_CUSTOMER_ROLES,
                    tabs: integrationsTabs,
                  },
                ]
              : []),
            {
              id: "integrations--azure",
              component: AzureIntegration,
              showInSideNavigation: !isAWSEnv,
              isDataGrid: true,
              title: isAWSEnv ? undefined : "Public Cloud",
              path: PrivateRoutesMap.AZURE_INTEGRATIONS,
              permission: ALL_CUSTOMER_ROLES,
              tabs: isAWSEnv ? integrationsTabs : AzureintegrationsTabs,
            },
            {
              id: "integrations--gcp",
              component: GCPIntegration,
              showInSideNavigation: false,
              isDataGrid: true,
              path: PrivateRoutesMap.GCP_INTEGRATIONS,
              permission: ALL_CUSTOMER_ROLES,
              tabs: isAWSEnv ? integrationsTabs : AzureintegrationsTabs,
            },
            {
              id: "integrations--splunk",
              component: SplunkIntegration,
              path: PrivateRoutesMap.SPLUNK_INTEGRATION,
              showInSideNavigation: true,
              title: "Event Collector",
              permission: ALL_CUSTOMER_ROLES,
              tabs: eventCollectorTabs,
            },
            {
              id: "integrations--api-log-collection",
              component: APIBasedLogCollection,
              path: PrivateRoutesMap.API_LOG_COLLECTION,
              showInSideNavigation: false,
              title: "API-Based Log Collection",
              permission: ALL_CUSTOMER_ROLES,
              tabs: eventCollectorTabs,
            },
            // {
            //   id: "integrations--mobile-device-management",
            //   component: MobileDeviceManagement,
            //   path: PrivateRoutesMap.MOBILE_DEVICE_MANAGEMENT,
            //   showInSideNavigation: true,
            //   title: "Mobile Device Mgmt",
            //   permission: ALL_CUSTOMER_ROLES,
            // },
            {
              id: "integrations--mobile-device-management-ms-intune",
              component: MobileDeviceManagement,
              path: PrivateRoutesMap.MS_INTUNE_INTEGRATION,
              showInSideNavigation: true,
              isDataGrid: true,
              title: "Mobile Device Mgmt",
              permission: ALL_CUSTOMER_ROLES,
              tabs: deviceManagementTabs,
            },
          ],
        },
        {
          id: "monitor-root",
          title: "Monitor",
          showInSideNavigation: true,
          permission: [Roles.CUSTOMER_SUPER_ADMIN, Roles.CUSTOMER_MONITOR_ADMIN],
          iconName: "monitor",
          childs: [
            {
              id: "monitor--alerts",
              component: Alerts,
              path: PrivateRoutesMap.ALERTS,
              showInSideNavigation: true,
              isDataGrid: true,
              title: "Alerts",
              isRelativeRoute: true,
              permission: ALL_CUSTOMER_ROLES,
            },
            {
              id: "monitor--network-policy-evaluation",
              component: NetworkPolicyEvaluation,
              path: PrivateRoutesMap.NETWORK_POLICY_EVALUATION,
              showInSideNavigation: true,
              title: "Troubleshooting",
              permission: [Roles.CUSTOMER_SUPER_ADMIN, Roles.CUSTOMER_MONITOR_ADMIN],
              tabs: troubleshootingTabs,
            },
            {
              id: "monitor--application-policy-evaluation",
              component: ApplicationPolicyEvaluation,
              path: PrivateRoutesMap.APPLICATION_POLICY_EVALUATION,
              showInSideNavigation: false,
              title: "Network Policy Evaluation & Troubleshooting",
              tabs: troubleshootingTabs,
              permission: [Roles.CUSTOMER_SUPER_ADMIN, Roles.CUSTOMER_MONITOR_ADMIN],
            },
            {
              id: "monitor--subscriptions",
              component: Subscriptions,
              path: PrivateRoutesMap.SUBSCRIPTIONS,
              showInSideNavigation: true,
              isDataGrid: true,
              title: "Subscriptions",
              isRelativeRoute: true,
              permission: ALL_CUSTOMER_ROLES,
            },
            {
              id: "dashboard--insights",
              component: ActivityLogs,
              showInSideNavigation: true,
              title: "Activity Logs",
              isRelativeRoute: true,
              path: PrivateRoutesMap.INSIGHTS,
              permission: ALL_CUSTOMER_ROLES,
              isDataGrid: true,
            },
          ],
        },
        {
          id: "monitor-root",
          title: "Monitor",
          showInSideNavigation: true,
          permission: [Roles.CUSTOMER_OBSERVER_ADMIN],
          iconName: "monitor",
          childs: [
            {
              id: "monitor--alerts",
              component: Alerts,
              path: PrivateRoutesMap.ALERTS,
              showInSideNavigation: true,
              isDataGrid: true,
              title: "Alerts",
              isRelativeRoute: true,
              permission: ALL_CUSTOMER_ROLES,
            },
            {
              id: "monitor--subscriptions",
              component: Subscriptions,
              path: PrivateRoutesMap.SUBSCRIPTIONS,
              showInSideNavigation: true,
              isDataGrid: true,
              title: "Subscriptions",
              isRelativeRoute: true,
              permission: ALL_CUSTOMER_ROLES,
            },
            {
              id: "dashboard--insights",
              component: ActivityLogs,
              showInSideNavigation: true,
              title: "Activity Logs",
              isRelativeRoute: true,
              path: PrivateRoutesMap.INSIGHTS,
              permission: ALL_CUSTOMER_ROLES,
            },
          ],
        },
        // {
        //   id: "settings-root",
        //   title: "Settings",
        //   showInSideNavigation: true,
        //   component: Settings,
        //   permission: ALL_CUSTOMER_ROLES,
        //   path: PrivateRoutesMap.SETTINGS,
        //   iconName: "settings",
        // },
        {
          id: "activity-logs-zta-admin",
          component: ActivityLogs,
          path: PrivateRoutesMap.ACTIVITY_LOGS,
          showInSideNavigation: true,
          isDataGrid: true,
          title: "Activity Logs",
          permission: [Roles.SUPER_ADMIN],
          iconName: "chart",
        },
        {
          id: "relayNodes-root",
          component: ExtremeRelayNodes,
          path: PrivateRoutesMap.MULTI_TENANTS_RELAY,
          showInSideNavigation: true,
          isDataGrid: true,
          title: "UZTNA Gateway",
          permission: [Roles.SUPER_ADMIN],
          iconName: "diamondIcon",
        },
        {
          id: "tenants-root",
          component: Tenants,
          path: PrivateRoutesMap.CLIENTS,
          showInSideNavigation: true,
          isDataGrid: true,
          title: "Tenants",
          permission: [Roles.SUPER_ADMIN],
          iconName: "users",
        },
        {
          id: "settings-root",
          title: "Identity Providers",
          permission: [Roles.SUPER_ADMIN],
          showInSideNavigation: true,
          iconName: "settings2",
          tabs: identityProviderTabs,
          path: PrivateRoutesMap.GLOBAL_IDENTITY_PROVIDERS,
          component: SuperAdminIDPs,
        },
        {
          id: "applications--public-saas-test",
          component: SuperAdminIDPs,
          showInSideNavigation: false,
          path: PrivateRoutesMap.GLOBAL_IDENTITY_PROVIDERS_MICROSOFT,
          permission: [Roles.SUPER_ADMIN],
          tabs: identityProviderTabs,
        },

        ...(isAgentlessSoftRemoved
          ? ([
              ...(publicSaas
                ? [
                    {
                      id: "end-user-applications-list-root",
                      component: EndUserApplications,
                      path: PrivateRoutesMap.END_USER_APPLICATIONS,
                      showInSideNavigation: true,
                      title: "Applications",
                      permission: [Roles.END_USER],
                      iconName: "enLayoutMasonry",
                    },
                  ]
                : []),
              {
                id: "end-user-root-devices--linked-active-devices",
                component: EndUserDevices,
                path: PrivateRoutesMap.END_USER_DEVICES,
                showInSideNavigation: true,
                title: "Devices",
                permission: [Roles.END_USER],
                iconName: "enLaptop",
              },
              {
                id: "end-user-root-devices--user-application-downloads",
                component: Downloads,
                path: PrivateRoutesMap.END_USER_DOWNLOADS,
                showInSideNavigation: true,
                title: "Downloads",
                permission: [Roles.END_USER],
                iconName: "enDownload",
              },
            ] as RouteType[])
          : ([
              {
                id: "end-user-root-devices",
                title: "Devices",
                permission: [Roles.END_USER],
                showInSideNavigation: true,
                iconName: "devices",
                childs: [
                  {
                    id: "end-user-root-devices--linked-active-devices",
                    component: EndUserDevices,
                    path: PrivateRoutesMap.END_USER_DEVICES,
                    showInSideNavigation: true,
                    title: "Devices",
                    permission: [Roles.END_USER],
                  },
                  {
                    id: "end-user-root-devices--user-application-downloads",
                    component: Downloads,
                    path: PrivateRoutesMap.END_USER_DOWNLOADS,
                    showInSideNavigation: true,
                    title: "Downloads",
                    permission: [Roles.END_USER],
                  },
                  ...(publicSaas
                    ? [
                        {
                          id: "end-user-applications-list-root",
                          component: EndUserApplications,
                          path: PrivateRoutesMap.END_USER_APPLICATIONS,
                          showInSideNavigation: true,
                          title: "Applications",
                          permission: [Roles.END_USER],
                          iconName: "case",
                        },
                      ]
                    : []),
                ],
              },
            ] as RouteType[])),
        {
          id: "profile-root",
          component: Profile,
          path: PrivateRoutesMap.PROFILE,
          title: "Profile",
          permission: [
            Roles.SUPER_ADMIN,
            Roles.END_USER,
            Roles.CUSTOMER_SUPER_ADMIN,
            Roles.CUSTOMER_MONITOR_ADMIN,
            Roles.CUSTOMER_OBSERVER_ADMIN,
          ],
          showInSideNavigation: false,
        },
      ],
    },
  ]
}

export const HomeRoutes = {
  [Roles.SUPER_ADMIN]: PrivateRoutesMap.ACTIVITY_LOGS.absolutePath,
  [Roles.CUSTOMER_SUPER_ADMIN]: PrivateRoutesMap.DASHBOARD_CHART.absolutePath,
  [Roles.CUSTOMER_MONITOR_ADMIN]: PrivateRoutesMap.DASHBOARD_CHART.absolutePath,
  [Roles.CUSTOMER_OBSERVER_ADMIN]: PrivateRoutesMap.DASHBOARD_CHART.absolutePath,
  [Roles.END_USER]: PublicRoutesMap.PREPARE_PORTAL.absolutePath,
} as const

export { Roles, RolesNameMapping } from "./Roles"
export type { RoleValueType } from "./Roles"
