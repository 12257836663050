import { PROFILE, TENANT_ADMIN_BASE_URL } from "src/constants"
import { RoleValueType } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import { API_URL, REQUEST_HEADERS } from "src/services"
import { SsoUser } from "src/store/auth/types"
import useSWR from "swr"

type ProfilePayload = {
  firstName: string
  lastName: string
  email: string
  role: string
  workspaceUrl: string
  onboardingStep: number
  id?: number
  workspaceId: number
  ssoUser?: SsoUser
  workspaceCompanyName?: string
  ownerId?: number
  isXcdAdmin?: boolean
  profileImage?: string
  environment?: string
  environmentUrl?: string
}

type UseProfileResponseType = {
  data?: ProfilePayload
  isLoading: boolean
  error: any
  isValidating: boolean
  getProfile: () => void
}

/**
 * Custom hook to fetch and manage the profile data of a user.
 *
 * This hook uses SWR to fetch profile data based on the user's role and whether fetching is required.
 * It provides a `getProfile` function that can be used to trigger a manual refetch.
 *
 * @param {Object} options - Options controlling the hook's behavior.
 * @param {boolean} options.suspense - Whether to suspend rendering until the data is ready (used with React Suspense).
 * @param {boolean} [options.shouldFetch] - Whether to fetch the profile data.
 * @param {RoleValueType} role - The role of the user to determine the correct API endpoint.
 *
 * @returns {UseProfileResponseType} An object containing the profile data, loading status, error, and validation state.
 */
const useProfile = (
  options: { suspense: boolean; shouldFetch?: boolean },
  role: RoleValueType,
): UseProfileResponseType => {
  const shouldFetch = options.shouldFetch !== undefined ? options.shouldFetch : true
  const url = isTenantRole(role) ? `${API_URL}${TENANT_ADMIN_BASE_URL}${PROFILE}` : `${API_URL}${PROFILE}`

  const { ownerId } = JSON.parse(localStorage.getItem("userData") || "{}")

  const { data, error, mutate, isValidating } = useSWR(shouldFetch ? [url, REQUEST_HEADERS] : null, {
    suspense: options.suspense,
  })

  const payload = { ...data?.payload, ownerId }

  return {
    data: payload || undefined,
    isLoading: !error && !data,
    error,
    getProfile: mutate,
    isValidating,
  }
}

export default useProfile
