import dayjs, { Dayjs } from "dayjs"
import timezonePlugin from "dayjs/plugin/timezone"
import timezoneList from "../AddEditTimeCondition/timezones.json"

dayjs.extend(timezonePlugin)

const zeroOffset = timezoneList.filter((zone) => zone.utc === "+00:00").map((zone) => zone.tzCode)

export const WeekMapping: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
}

export const WeekMappingReverse: string[] = Object.keys(WeekMapping)

/**
 * Parses a given timezone and returns a Dayjs object.
 *
 * @param {string} timezone - The timezone to parse.
 * @param {string | Dayjs} [val] - An optional value to be parsed. If not provided, the current time is used.
 * @param {boolean} [keepLocal=false] - Whether to keep the local time or not.
 * @returns {Dayjs} - A Dayjs object representing the parsed time.
 */
export const parseTimezone = (timezone: string, val?: string | Dayjs, keepLocal: boolean = false) => {
  if (zeroOffset.includes(timezone)) {
    if (!val) {
      return dayjs.utc()
    }
    return dayjs.utc(val)
  } else {
    if (!val) {
      return dayjs().tz(timezone, keepLocal)
    }
    return dayjs(val).tz(timezone, keepLocal)
  }
}

/**
 * Combines a given date and time into a single Dayjs object.
 *
 * @param {Dayjs | null | undefined} date - The date to which the time will be added. If null or undefined, `currentDate` will be used.
 * @param {Dayjs | null | undefined} time - The time to be added to the date. If null or undefined, the time will not be modified.
 * @param {Dayjs} [currentDate] - The current date to use if `date` is null or undefined.
 * @returns {Dayjs | null} - A new Dayjs object with the combined date and time, or null if both `date` and `currentDate` are null or undefined.
 */
export const addTimeToDate = (date: Dayjs | null | undefined, time: Dayjs | null | undefined, currentDate?: Dayjs) => {
  let dateTimeCombined = !!date
    ? date.clone().hour(0).minute(0).second(0)
    : currentDate
    ? currentDate.hour(0).minute(0).second(0)
    : null

  if (dateTimeCombined) {
    dateTimeCombined = time?.hour() ? dateTimeCombined.hour(time.hour()) : dateTimeCombined
    dateTimeCombined = time?.minute() ? dateTimeCombined.minute(time.minute()) : dateTimeCombined
    dateTimeCombined = time?.second() ? dateTimeCombined.second(time.second()) : dateTimeCombined
  }

  return dateTimeCombined
}

/**
 * Maps an array of day names to an array of corresponding numbers based on a predefined week mapping.
 *
 * @param {string[]} daysArray - An array of day names (e.g., ["Monday", "Tuesday"]).
 * @returns {number[]} An array of numbers corresponding to the day names, sorted in ascending order.
 */
export const mapDaysToNumberArray = (daysArray: string[]): number[] => {
  // @ts-ignore
  const numArr = daysArray?.map((day) => WeekMapping[day.toLowerCase()]).toSorted((a, b) => a - b)
  return numArr
}

/**
 * Maps an array of numbers to their corresponding day names.
 *
 * @param numArray - An array of numbers representing days of the week (1 for Monday, 2 for Tuesday, etc.).
 * @returns An array of strings where each string is the name of the day corresponding to the input number.
 */
export const mapNumberArrayToDays = (numArray: number[]): string[] => {
  const daysArr = numArray.map((day) => WeekMappingReverse[day - 1])
  return daysArr
}
