import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { DATE_FORMATS } from "src/constants"
import { CustomerAdminLayout, LayoutPoints } from "src/constants/layout"
import { ModalContentType } from "src/pages/ActivityLogs/ViewMoreModal/ViewMoreDetailsModal"
import { RouteWithoutComponentType } from "src/routes/config"
import { FEATURE_FLAGS_LOCAL_STORAGE_KEY } from "src/utils/constants"
import {
  BackgroundType,
  BreadcrumbsBadgeType,
  BreadcrumbsType,
  DateTimeFormatType,
  DrawerNamesType,
  FeatureFlagsResponseType,
  FeatureFlagsType,
  ModalNamesType,
  SignalType,
  ToasterType,
  UIState,
  UpgradeNotificationsDataType,
  UpgradeRelayStateType,
} from "./types"

const localStorageFeatureFlags = JSON.parse(localStorage.getItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY) || JSON.stringify({}))

function isFeatureFlagsType(obj: any): obj is FeatureFlagsType {
  return (
    typeof obj === "object" &&
    typeof obj.agentlessSoftRemoval === "boolean" &&
    typeof obj.enableCraas === "boolean" &&
    typeof obj.publicSaas === "boolean" &&
    typeof obj.isFeatureFlagsLoading === "boolean"
  )
}

export const initialState: UIState = {
  openedModals: {} as Record<ModalNamesType, true>,
  rowsPerPage: {},
  drawerName: null,
  layout: undefined,
  isLayoutLoading: true,
  dateTimeFormat: {
    dateFormat: DATE_FORMATS.US.H12.date,
    timeFormat: DATE_FORMATS.US.H12.time,
    dateTimeFormat: DATE_FORMATS.US.H12.dateTime,
  },
  routeInfo: {
    checkedRoutes: {},
    allowedRoutes: [],
    currentRoute: undefined,
  },
  appContainer: {
    shouldRenderAppContainer: true,
    showTopNav: true,
    showDrawer: true,
    defaultSpacing: true,
    showBreadcrumbs: false,
    showPageHeader: false,
    background: "white",
  },
  sideNav: {
    isOpened: true,
    currentRouteId: undefined,
    isHidden: false,
  },
  breadCrumbs: { data: [] },
  loadingState: "",
  toasterState: {
    message: undefined,
    type: "success",
    autoHide: true,
  },
  dashboardRefreshInterval: 60000,
  featureFlags: isFeatureFlagsType(localStorageFeatureFlags)
    ? localStorageFeatureFlags
    : { agentlessSoftRemoval: true, enableCraas: false, isFeatureFlagsLoading: true, publicSaas: true },
  upgradeNotificationsData: { connectorUpgradeData: null, relayNodeUpgradeData: null, radsecProxyUpgradeData: null },
  signals: [],
  allowOpenEncrytionProtocols: false,
  upgradeRelayState: "pending",
  dashboardTime: dayjs().format(DATE_FORMATS.US.H12.timeWithSeconds),
  refreshTrigger: false,
  isDevicePostureUnsaved: false,
  isPolicyOrderInProgress: false,
  devicePostureMessage: "",
  showLicenseValidation: false,
  activityLogsViewMoreModalContent: undefined,
  activeTab: 0,
}

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    triggerRefresh: (state) => {
      state.refreshTrigger = !state.refreshTrigger
    },
    updateTime: (state) => {
      const currentTime = dayjs().format(DATE_FORMATS.US.H12.timeWithSeconds)
      state.dashboardTime = currentTime
    },
    openModal: (state, action: PayloadAction<ModalNamesType>) => {
      const modalName = action.payload
      state.openedModals[modalName] = true
    },
    closeModal: (state, action: PayloadAction<ModalNamesType>) => {
      const modalName = action.payload
      if (modalName in state.openedModals) {
        delete state.openedModals[modalName]
      }
    },
    setOpenedModals: (state, action: PayloadAction<Record<ModalNamesType, true>>) => {
      state.openedModals = action.payload
    },
    openDrawer(state, { payload }: PayloadAction<DrawerNamesType>) {
      if (state.drawerName !== payload) {
        state.drawerName = payload
      }
    },
    setRowsPerPage: (state, action: PayloadAction<{ routePath: string; rowsPerPage: number }>) => {
      const { routePath, rowsPerPage } = action.payload
      if (routePath) {
        state.rowsPerPage[routePath] = rowsPerPage
      }
    },
    closeDrawer(state) {
      if (state.drawerName) {
        state.drawerName = null
      }
    },
    setLoadingState(state, { payload }: PayloadAction<string>) {
      state.loadingState = payload
    },
    clearLoadingState(state) {
      state.loadingState = ""
    },
    setToasterState(state, { payload }: PayloadAction<ToasterType>) {
      state.toasterState = payload
    },
    toggleSideNav(state) {
      state.sideNav.isOpened = !state.sideNav.isOpened
    },
    setIsSideNavOpened(state, { payload }: PayloadAction<boolean>) {
      state.sideNav.isOpened = payload
    },
    setAllowedRoutes(state, { payload }: PayloadAction<RouteWithoutComponentType[]>) {
      state.routeInfo.allowedRoutes = payload
    },
    setCurrentRoute(state, { payload }: PayloadAction<RouteWithoutComponentType | undefined>) {
      state.routeInfo.currentRoute = payload
    },
    setCheckedRoute(state, { payload }: PayloadAction<[number, boolean]>) {
      state.routeInfo.checkedRoutes[payload[0]] = payload[1]
    },
    setSideNavCurrentRoute(state, { payload }: PayloadAction<string | undefined>) {
      state.sideNav.currentRouteId = payload
    },
    disableSideNav(state) {
      state.sideNav.isHidden = true
    },
    enableSideNav(state) {
      state.sideNav.isHidden = false
    },
    openSideNav(state) {
      state.sideNav.isOpened = true
    },
    toggleShowDrawer(state) {
      state.appContainer.showDrawer = !state.appContainer.showDrawer
    },
    setShowDrawer(state, { payload }: PayloadAction<boolean>) {
      state.appContainer.showDrawer = payload
    },
    setShowTopNav(state, { payload }: PayloadAction<boolean>) {
      state.appContainer.showTopNav = payload
    },
    setAppContainerRendering(state, { payload }: PayloadAction<boolean>) {
      state.appContainer.shouldRenderAppContainer = payload
    },
    setAppContainerDefaultSpacing(state, { payload }: PayloadAction<boolean>) {
      state.appContainer.defaultSpacing = payload
    },
    setAppContainerBG(state, { payload }: PayloadAction<BackgroundType>) {
      state.appContainer.background = payload
    },
    resetAppContainerBG: (state) => {
      state.appContainer.background = "white"
    },
    setBreadCrumbs(state, { payload }: PayloadAction<BreadcrumbsType>) {
      state.appContainer.showBreadcrumbs = true
      state.breadCrumbs = payload
    },
    showPageHeader(state) {
      state.appContainer.showPageHeader = true
    },
    hidePageHeader(state) {
      state.appContainer.showPageHeader = false
    },
    setBreadCrumbsBadge(state, { payload }: PayloadAction<BreadcrumbsBadgeType>) {
      state.appContainer.showBreadcrumbs = true
      state.breadCrumbs.badge = payload
    },
    resetBreadCrumbs: (state) => {
      state.appContainer.showBreadcrumbs = false
      state.breadCrumbs = { data: [] }
    },
    setDateTimeFormat(state, { payload }: PayloadAction<DateTimeFormatType>) {
      state.dateTimeFormat = payload
    },
    resetUI: () => initialState,
    setLayout(state, { payload }: PayloadAction<LayoutPoints[] | CustomerAdminLayout | undefined>) {
      state.layout = payload
    },
    setIsLayoutLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLayoutLoading = payload
    },
    setFeatureFlagsFromResponse(state, { payload }: PayloadAction<FeatureFlagsResponseType>) {
      const flags = {
        agentlessSoftRemoval: payload.drap !== "ENABLED",
        enableCraas: payload.enableCraas,
        isFeatureFlagsLoading: false,
        publicSaas: payload?.publicSaas,
        devicePosture: payload?.devicePosture,
        appDiscovery: payload?.appDiscovery,
      }
      state.featureFlags = flags
      localStorage.setItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY, JSON.stringify(flags))
    },
    setConnectorsNotificationsData(
      state,
      { payload }: PayloadAction<UpgradeNotificationsDataType["connectorUpgradeData"]>,
    ) {
      state.upgradeNotificationsData.connectorUpgradeData = payload
    },
    setRelayNodesNotificationsData(
      state,
      { payload }: PayloadAction<UpgradeNotificationsDataType["relayNodeUpgradeData"]>,
    ) {
      state.upgradeNotificationsData.relayNodeUpgradeData = payload
    },
    setRadsecProxyNotificationsData(
      state,
      { payload }: PayloadAction<UpgradeNotificationsDataType["radsecProxyUpgradeData"]>,
    ) {
      state.upgradeNotificationsData.radsecProxyUpgradeData = payload
    },
    updateSignals(state, { payload }: PayloadAction<SignalType[]>) {
      state.signals = payload
    },
    setAllowOpenEncrytionProtocols(state, { payload }: PayloadAction<boolean>) {
      state.allowOpenEncrytionProtocols = payload
    },
    setUpgradeRelayState(state, { payload }: PayloadAction<UpgradeRelayStateType>) {
      state.upgradeRelayState = payload
    },
    setIsDevicePostureUnsaved(state, { payload }: PayloadAction<boolean>) {
      state.isDevicePostureUnsaved = payload
    },
    setDevicePostureMessage(state, { payload }: PayloadAction<string>) {
      state.devicePostureMessage = payload
    },
    setIsPolicyOrderInProgress(state, { payload }: PayloadAction<boolean>) {
      state.isPolicyOrderInProgress = payload
    },
    setShowLicenseValidation(state, { payload }: PayloadAction<boolean>) {
      state.showLicenseValidation = payload
    },
    setActivityLogsViewMoreModalContent(state, { payload }: PayloadAction<ModalContentType | undefined>) {
      state.activityLogsViewMoreModalContent = payload
    },
    setActiveTab(state, { payload }: PayloadAction<number>) {
      state.activeTab = payload
    },
  },
})

export const {
  openModal,
  closeModal,
  setRowsPerPage,
  openDrawer,
  closeDrawer,
  setLoadingState,
  clearLoadingState,
  toggleSideNav,
  setIsSideNavOpened,
  setAllowedRoutes,
  setCurrentRoute,
  setCheckedRoute,
  openSideNav,
  setShowDrawer,
  setShowTopNav,
  setToasterState,
  setBreadCrumbs,
  showPageHeader,
  hidePageHeader,
  resetBreadCrumbs,
  setLayout,
  setDateTimeFormat,
  setAppContainerRendering,
  setAppContainerDefaultSpacing,
  setSideNavCurrentRoute,
  disableSideNav,
  enableSideNav,
  setFeatureFlagsFromResponse,
  setConnectorsNotificationsData,
  setRelayNodesNotificationsData,
  updateSignals,
  setAllowOpenEncrytionProtocols,
  setUpgradeRelayState,
  updateTime,
  triggerRefresh,
  setIsDevicePostureUnsaved,
  setIsPolicyOrderInProgress,
  setDevicePostureMessage,
  setShowLicenseValidation,
  setActivityLogsViewMoreModalContent,
  setRadsecProxyNotificationsData,
  setActiveTab,
} = uiSlice.actions

export default uiSlice.reducer
